import classnames from 'classnames'
import { noop } from 'lodash'
import * as React from 'react'
import { StyledIcons } from '../../../icons'
import { Box } from '../../Box'
import { WithTooltip } from '../../Tooltip'
import stylesheet from './NavItem.module.scss'

type DropdownNavItemProps = {
  hidden?: boolean
  label: React.ReactElement | React.ReactNode
  children: React.ReactNode
  onClick?: () => void
  active?: boolean
  isIcon?: boolean
  trigger?: 'hover' | 'click'
}

function DropdownNavItem({
  hidden,
  label,
  children,
  onClick = noop,
  active,
  isIcon = false,
  trigger = 'hover',
  ...props
}: DropdownNavItemProps): JSX.Element | null {
  const labelRef = React.useRef<HTMLElement>(null)
  const mountRef = React.useRef<HTMLButtonElement>(null)
  if (hidden) {
    return null
  }

  return (
    <Box as="li" ref={mountRef} className={stylesheet.navItem}>
      <WithTooltip
        isAnimated={false}
        trigger={trigger}
        hideArrow
        fixed={false}
        mountAt={mountRef.current}
        ref={labelRef}
        bg="white"
        content={
          <Box>
            <Box as="ul" css={{ listStyleType: 'none' }}>
              {React.Children.map(children, (child) => (
                <Box>{child}</Box>
              ))}
            </Box>
          </Box>
        }
      >
        <button
          {...props}
          tabIndex={0}
          onClick={onClick}
          className={classnames(stylesheet.navItemLink, {
            [stylesheet.dropdownNavIcon]: isIcon,
          })}
        >
          <Box height={3} width="100%" bg="transparent" />
          <Box display="flex" flexDirection="row">
            {label}
            <Box ml={1}>
              <StyledIcons.Chevron height={10} width={10} color="slates.50" />
            </Box>
          </Box>
          <Box
            height={3}
            width="100%"
            bg={active ? 'greens.50' : 'transparent'}
          />
        </button>
      </WithTooltip>
    </Box>
  )
}
export { DropdownNavItem }
