import * as React from 'react'
import { GroupBase, OptionProps, components } from 'react-select'
import {
  CellMeasurer,
  CellMeasurerCache,
  AutoSizer,
  List as VirtualizedList,
} from 'react-virtualized'
import { isTestEnv } from '@voltus/utils'
import { ActivityIndicator } from '../ActivityIndicator'
import { useTheme } from '../ThemeProvider'
import * as DROPDOWN_TEST_SELECTORS from './Dropdown.testSelectors'
import * as SELECTORS from './Dropdown.testSelectors'

export const CustomDropdownIndicator = (props) => {
  return <components.DropdownIndicator {...props} />
}

export function CustomOption<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ innerProps, ...props }: OptionProps<Option, IsMulti, Group>) {
  return (
    <components.Option
      innerProps={{
        ...innerProps,
        // React-select's innerProps types expect plain HTML attributes, and they can't
        // handle data-* attributes. I was having a hard time figuring out how to properly
        // include 'data-testid' in the types, so for now just ignore the error, it's harmless
        // eslint-disable-next-line
        // @ts-ignore
        'data-testid': SELECTORS.OPTION,
      }}
      {...props}
    />
  )
}

export const CustomMenuList = (props) => {
  const { colors } = useTheme()
  const { selectProps } = props
  const { isVirtualized, rowHeight } = selectProps
  const focusedOption = props.options.indexOf(props.focusedOption)

  const cellMeasurerCache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 33,
    })
  )?.current

  // This is a bit dangerous as it means we can't actually
  // test the virtualization behavior in tests, but it's a
  // trade-off to make for now to get tests passing
  if (isTestEnv()) {
    return (
      <div data-testid={DROPDOWN_TEST_SELECTORS.CUSTOM_MENU_LIST}>
        <components.MenuList {...props} />
      </div>
    )
  }

  if (isVirtualized || props.options.length > 100) {
    return (
      <AutoSizer disableHeight>
        {({ width }) => {
          return (
            <VirtualizedList
              height={props.maxHeight}
              rowCount={props.children.length ?? 0}
              rowHeight={({ index }) => {
                if (typeof rowHeight === 'number') {
                  return rowHeight
                }

                return cellMeasurerCache.rowHeight({ index })
              }}
              scrollToIndex={focusedOption}
              rowRenderer={({ index, style, key, parent }) => {
                return (
                  <CellMeasurer
                    cache={cellMeasurerCache}
                    key={key}
                    columnIndex={0}
                    parent={parent}
                    rowIndex={index}
                  >
                    {({ registerChild }) => {
                      return (
                        <div
                          ref={registerChild as React.LegacyRef<HTMLDivElement>}
                          style={{
                            ...style,
                            width: width,
                            borderBottom: `1px solid ${colors.grays['10']}`,
                          }}
                        >
                          {props.children[index] ?? null}
                        </div>
                      )
                    }}
                  </CellMeasurer>
                )
              }}
              width={width}
            />
          )
        }}
      </AutoSizer>
    )
  }
  return <components.MenuList {...props} />
}

export const CustomLoadingIndicator = () => {
  return <ActivityIndicator.ExtraSmall />
}
