import React from 'react'
import colors from '../constants/colors.json'
import createSVGComponent from './createSVGComponent'

export default createSVGComponent(({ color = colors.grays[20], ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    {...props}
  >
    <path d="M4 21V8a3 3 0 013-3h10a3 3 0 013 3v6a3 3 0 01-3 3H8l-4 4M8 9h8m-8 4h6" />
  </svg>
))
