import classnames from 'classnames'
import camelCase from 'lodash/camelCase'
import noop from 'lodash/noop'
import upperFirst from 'lodash/upperFirst'
import * as React from 'react'
import { StyledProps } from '../../utils/styledSystem'
import { ActivityIndicator } from '../ActivityIndicator'
import { Box } from '../Box'
import { Flex } from '../Flex'

import stylesheet from './Button.module.scss'
import { BUTTON_SIZE, BUTTON_KIND } from './constants'

export type ButtonProps = StyledProps & {
  'data-testid'?: string
  appearDisabled?: boolean
  children?: React.ReactNode
  className?: string
  isLoading?: boolean
  isError?: boolean
  kind?: `${BUTTON_KIND}` | Lowercase<`${BUTTON_KIND}`>
  size?: `${BUTTON_SIZE}`
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  onClick?: (e: React.MouseEvent) => void
  disabled?: boolean
  isDisabled?: boolean
  fit?: boolean
}

/**
 * Voltus button component, button type is determined by BUTTON_KIND props
 */
const BaseButton = React.forwardRef<HTMLElement, ButtonProps>(
  function ButtonForwardRef(
    {
      appearDisabled = false,
      children,
      className,
      isError = false,
      isLoading,
      kind = BUTTON_KIND.DEFAULT,
      leftIcon,
      onClick,
      rightIcon,
      size,
      disabled,
      isDisabled,
      fit,
      ...props
    },
    ref
  ) {
    const renderBody = () => {
      return (
        <Flex.Row
          alignItems="center"
          justifyContent="center"
          as="span"
          gap={2}
          css={
            isLoading
              ? {
                  opacity: 0,
                }
              : {}
          }
        >
          {leftIcon && <span>{leftIcon}</span>}
          {children}
          {rightIcon && <span>{rightIcon}</span>}
        </Flex.Row>
      )
    }

    // Allow for kind to be case-insensitive
    const screamingKind = kind.toUpperCase()

    return (
      <Box
        as="button"
        type="button"
        ref={ref}
        onClick={
          isLoading
            ? noop
            : (...args) => {
                // Don't call onClick if we are either disabled
                // or just appear disabled
                if (isDisabled || disabled || appearDisabled) {
                  return
                }
                return onClick?.(...args)
              }
        }
        {...props}
        disabled={disabled || isDisabled}
        className={classnames(className, stylesheet.btn, {
          [stylesheet.btnprimary]: screamingKind === BUTTON_KIND.DEFAULT,
          [stylesheet.btnsuccess]: screamingKind === BUTTON_KIND.SUCCESS,
          [stylesheet.btnwarning]: screamingKind === BUTTON_KIND.WARNING,
          [stylesheet.btndanger]: screamingKind === BUTTON_KIND.DANGER,
          [stylesheet.btnTransparent]:
            screamingKind === BUTTON_KIND.TRANSPARENT,
          [stylesheet.btnInline]: screamingKind === BUTTON_KIND.INLINE,
          [stylesheet.btnOutlineprimary]:
            screamingKind === BUTTON_KIND.DEFAULT_OUTLINE,
          [stylesheet.btnOutlinesuccess]:
            screamingKind === BUTTON_KIND.SUCCESS_OUTLINE,
          [stylesheet.btnOutlinewarning]:
            screamingKind === BUTTON_KIND.WARNING_OUTLINE,
          [stylesheet.btnOutlinedanger]:
            screamingKind === BUTTON_KIND.DANGER_OUTLINE,
          [stylesheet.withIcon]: leftIcon || rightIcon,
          [stylesheet.loading]: isLoading,
          [stylesheet.disabled]: appearDisabled,
          [stylesheet.sizesmall]: size === BUTTON_SIZE.SMALL,
          [stylesheet.error]: isError,
          [stylesheet.fit]: fit,
        })}
      >
        {isLoading && size !== 'small' && (
          <Flex.FullyCentered position="absolute">
            <ActivityIndicator.Small />
          </Flex.FullyCentered>
        )}
        {isLoading && size === 'small' && (
          <Flex.FullyCentered position="absolute">
            <ActivityIndicator.ExtraSmall />
          </Flex.FullyCentered>
        )}
        {renderBody()}
      </Box>
    )
  }
)

/**
 * Create aliases to different button kinds on the Button itself.
 * BUTTON_KIND keys are transformed to PascalCase, i.e.
 *
 * BUTTON_KIND.DEFAULT can be accessed by Button.Default
 * BUTTON_KIND.INLINE can be accessed by Button.Inline
 */
Object.entries(BUTTON_KIND).forEach(([key, val]) => {
  Object.assign(BaseButton, {
    [upperFirst(camelCase(key))]: React.forwardRef<HTMLElement, ButtonProps>(
      function ButtonForwardRef(props, ref) {
        return <BaseButton ref={ref} kind={val} {...props} />
      }
    ),
  })
})

interface Button {
  (props: ButtonProps): JSX.Element
  Default: (props: ButtonProps) => JSX.Element
  Success: (props: ButtonProps) => JSX.Element
  Warning: (props: ButtonProps) => JSX.Element
  Danger: (props: ButtonProps) => JSX.Element
  Transparent: (props: ButtonProps) => JSX.Element
  Inline: (props: ButtonProps) => JSX.Element
  DefaultOutline: (props: ButtonProps) => JSX.Element
  SuccessOutline: (props: ButtonProps) => JSX.Element
  WarningOutline: (props: ButtonProps) => JSX.Element
  DangerOutline: (props: ButtonProps) => JSX.Element
}

const Button = BaseButton as unknown as Button

export { Button }
