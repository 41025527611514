import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Link, RouteProps, useNavigate } from 'react-router-dom'
import stylesheet from './QuickNavBar.module.scss'

type QuickNavBarResultsProps = RouteProps & {
  onPageSelect: (path?: string) => void
  pages: Array<string>
}

const QuickNavBarResults = ({
  pages,
  onPageSelect,
}: QuickNavBarResultsProps) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const navigate = useNavigate()

  useEffect(() => {
    const listener = (e) => {
      if (e.key === 'Enter') {
        navigate(pages[selectedIndex])
        onPageSelect(pages[selectedIndex])
      }
      if (e.key === 'ArrowUp') {
        setSelectedIndex((state) =>
          state - 1 < 0 ? pages.length - 1 : state - 1
        )
      }

      if (e.key === 'ArrowDown') {
        setSelectedIndex((state) => (state + 1) % pages.length)
      }
    }

    window.addEventListener('keydown', listener)

    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, [selectedIndex, pages, navigate, onPageSelect])

  return pages.length ? (
    <div className={stylesheet.results}>
      {pages.map((path, index) => (
        <Link
          to={path}
          className={classnames(stylesheet.link, {
            [stylesheet.selected]: selectedIndex === index,
          })}
          onClick={() => {
            navigate(path)
            onPageSelect(path)
          }}
          key={path}
        >
          {path}
        </Link>
      ))}
    </div>
  ) : null
}

export default QuickNavBarResults
