import { ObjectValues } from '@voltus/types'

export const TEAM = {
  // FRONTEND is the generic fallback for things that don't belong to any team.
  FRONTEND: 'frontend',
  POD_COMMS: 'pod-comms',
  POD_AF: 'pod-af',
  POD_CX: 'pod-cx',
  POD_VOLTLETS: 'pod-voltlets',
  POD_DISPATCH: 'pod-dispatch',
  POD_MONOCLE: 'pod-monocle',
  POD_REGISTRATION_AND_SCHEDULING: 'pod-registration-and-scheduling',
  POD_TELEMETRY: 'pod-telemetry',
  POD_DEVELOPER_ECOSYSTEM: 'pod-developer-ecosystem',
  POD_PERFORMANCE: 'pod-performance',
} as const

export type Team = ObjectValues<typeof TEAM>
