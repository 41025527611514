export const Flags = {
  AUTO_ADJUSTMENTS_SITE_CONFIG_FORM: {
    value: 'auto-adjustments-site-config-form',
  },
  PARAMETERIZED_AESO_RESTATEMENT: {
    value: 'parameterized-aeso-restatement',
  },
  GRITS_VOLTLETS: {
    value: 'grits-voltlets',
  },
  VOLTLET_DETAILS_EXEMPTIONS: {
    value: 'voltlet-details-exemptions',
  },
  VOLTLET_EVENT_AND_ACTIVATION_TIMELINES: {
    value: 'voltlet-event-and-activation-timelines',
  },
  RTED_UNSMOOTHED: {
    // experimental flag to show if we removed all smoothing from RTED
    value: 'rted-remove-smoothing',
  },
  RTED_EXPANDED_COMPARE_VIEW: {
    value: 'expanded-compare-view',
  },
  BOARDS: {
    value: 'dashboards',
  },
  DISPLAY_APPLICATION_INTEGRATION: {
    value: 'display-application-integration',
  },
  PAST_PERFORMANCE_CSV_UPDATES: {
    value: 'past-performance-csv-updates',
  },
  KCE_INGEST: {
    value: 'kce-ingest',
  },
  RTED_AGGREGATE_METHOD: {
    value: 'load-aggregate-method',
  },
  PORTFOLIO_VIEW_CASHDASH: {
    value: 'portfolio-view-cashdash',
  },
  MISO_PRICE_OFFER: {
    value: 'miso-price-offer',
  },
  DISPATCH_QUEUE_SEND_EMAIL: {
    value: 'dispatch-queue-send-email',
  },
  RTED_UTILITY_DATA: {
    value: 'rted-utility-data',
  },
  COMMS_DASH_FACILITY_LIST_VIEW: {
    value: 'comms-dash-facility-list-view',
  },
  ACTION_ITEMS_ADMIN: {
    value: 'action-items-admin',
  },
  LOA_ADMIN: {
    value: 'loa-admin',
  },
  CASH_DASH_EARNINGS_TAB: {
    value: 'cash-dash-earnings-tab',
  },
  BULK_MANAGE_FACILITIES_PORTFOLIO: {
    value: 'bulk-manage-facilities-portfolio',
  },
} as const
