import { datadogRum } from '@datadog/browser-rum'
import * as React from 'react'
import { Team, TEAM } from '@voltus/constants'

/**
 * Sets the Datadog RUM view context property for the 'team'.
 * This allows team-specific analytics and monitoring in the Datadog dashboard
 * by associating the current view with a specific team.
 * https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#view-context
 */

const DatadogViewContext = React.createContext<Team>(TEAM.FRONTEND)

export const DatadogViewProvider = ({
  team,
  children,
}: {
  team: Team
  children: React.ReactNode
}) => {
  datadogRum.setViewContextProperty('team', team)

  return (
    <DatadogViewContext.Provider value={team}>
      {children}
    </DatadogViewContext.Provider>
  )
}

export const useDatadogView = (): Team => {
  const context = React.useContext(DatadogViewContext)
  if (context === undefined) {
    throw new Error('useDatadogView must be used within a DatadogView provider')
  }
  return context
}
