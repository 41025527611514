import classnames from 'classnames'
import React from 'react'
import { Any } from '@voltus/types'
import { StyledIcons } from '../../../icons'
import { Box } from '../../Box'
import stylesheet from './NavItem.module.scss'

type IconNavItemProps = {
  [key: string]: Any
  active?: boolean
  color?: string
  isStrokeBased?: boolean
  activeColor?: string
  icon: string
  width?: number
  height?: number
  iconHeight?: number
  iconWidth?: number
  containerIs?: React.ElementType
  is?: React.ElementType
}

export function IconNavItem({
  active,
  color = 'slates.50',
  isStrokeBased = false,
  activeColor = 'greens.50',
  icon,
  containerIs = 'li',
  width = 33,
  height = 33,
  iconWidth = 18,
  iconHeight = 18,
  is = 'a',
  ...props
}: IconNavItemProps): JSX.Element {
  const Icon = StyledIcons[icon]
  return (
    <Box as={containerIs} className={stylesheet.navItemIcon}>
      <Box
        as={is}
        {...props}
        width={width}
        height={height}
        className={classnames(
          stylesheet.navItemLink,
          stylesheet.navItemLinkIcon
        )}
      >
        <Icon
          width={iconWidth}
          height={iconHeight}
          isStrokeBased={isStrokeBased}
          color={active ? activeColor : color}
        />
      </Box>
    </Box>
  )
}
