import React from 'react'
import colors from '../constants/colors.json'
import createSVGComponent from './createSVGComponent'
const Dot = createSVGComponent(({ color = colors.grays[20], ...props }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="5" cy="5" r="5" fill={color} />
  </svg>
))

// eslint-disable-next-line
// @ts-ignore
Dot.Active = (props) => <Dot {...props} />

// eslint-disable-next-line
// @ts-ignore
Dot.Inactive = (props) => <Dot color={colors.grays[10]} {...props} />

export default Dot
