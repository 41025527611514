import fuzzy from 'fuzzy'
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'
import { RouteConfig } from '@voltus/types'
import { Input } from '../Input'
import stylesheet from './QuickNavBar.module.scss'
import Results from './QuickNavBarResults'

type QuickNavBarProps = {
  routes: Array<RouteConfig>
}

export const QuickNavBar = ({ routes }: QuickNavBarProps) => {
  const [visible, setVisible] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const inputRef = useRef<HTMLInputElement>()
  const keyMap = useRef<Array<string>>([])

  useEffect(() => {
    const listener = (e) => {
      if (e.key === 'Escape') {
        setVisible(false)
        setSearchValue('')
        keyMap.current = []
      } else if (e.key === '?') {
        // Need to hit question mark twice to trigger navbar
        keyMap.current.push('?')
        if (keyMap.current.length >= 2) {
          setVisible(true)
          setTimeout(() => {
            inputRef.current?.focus()
          })
        }
      } else {
        keyMap.current = []
      }
    }
    window.addEventListener('keyup', listener)

    return () => {
      keyMap.current = []
      window.removeEventListener('keyup', listener)
    }
  }, [])

  const filteredPages = fuzzy
    .filter(searchValue, routes, {
      extract: ({ path }) => path,
    })
    .map(({ string }) => string)

  return visible
    ? ReactDOM.createPortal(
        <FocusLock>
          <div
            onClick={() => setVisible(false)}
            className={stylesheet.container}
          >
            <div
              onClick={(e) => {
                e.stopPropagation()
              }}
              className={stylesheet.innerContainer}
            >
              <Input
                inputRef={inputRef}
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchValue(e.target.value)
                }
                className={stylesheet.input}
              />
              <Results
                onPageSelect={() => {
                  setSearchValue('')
                  setVisible(false)
                }}
                pages={filteredPages}
              />
            </div>
          </div>
        </FocusLock>,
        document.body
      )
    : null
}
