import { AnyFunction, AnyObject } from '@voltus/types'
import { handleFacilitySearch } from './demoHandlers/fetchOverrides'
import {
  handleFacilitySearchRes,
  handlePowerboxFilters,
} from './demoHandlers/sanitizeHandlers'
import { FetchMethods } from './types'

type RegExString = string
type SwapDirective = {
  _swap: Array<{
    field: string | null
    type: string
    key?: string
    optional?: boolean
  }>
}
type SanitizeFn = (
  uri: string,
  method: FetchMethods,
  data: unknown,
  ctx?: AnyObject
) => unknown
type SanitizeConfig =
  | { [key: string]: string | SanitizeConfig | SwapDirective }
  | SwapDirective
  | SanitizeFn
  | 'pass'
type RequestOverride = 'pass' | AnyFunction
type MethodConfig =
  | 'pass'
  | 'block'
  // You can have a response handler without a request handler
  | { req?: RequestOverride; res: SanitizeConfig }
  // If you define a request handler, you must _also_ define a response handler
  | { req: RequestOverride; res: SanitizeConfig }
type APIConfig = {
  GET?: MethodConfig
  POST?: MethodConfig
  PUT?: MethodConfig
  DELETE?: MethodConfig
  PATCH?: MethodConfig
}
type Replacements = {
  generic: AnyObject
  production: {
    user: AnyObject
    organization: AnyObject
    portfolio: AnyObject
    facility: AnyObject
    cohort: AnyObject
  }
  development: {
    user: AnyObject
    organization: AnyObject
    portfolio: AnyObject
    facility: AnyObject
    cohort: AnyObject
  }
}

export type DemoConfig = {
  allowedRoutes: Record<string, unknown>
  api: Record<RegExString, APIConfig>
  replacement: Replacements
}

export const config: DemoConfig = {
  allowedRoutes: {
    '/': {},
    '/cashdash/*': {},
    '/dispatches/*': {},
    '/portfolio/*': {},
    '/dashboard': {},
    '/realtime-energy/*': {},
    '/reports/*': {},
  },
  api: {
    '^/api/v0.1/permission/identity$': { GET: 'pass' },
    '^/api/v0.1/user-alerts\\??.*$': { GET: 'pass' },
    '^/api/portfolio-dash/[0-9]+/registrations/csv-monthly-breakdown\\?.*$': {
      // No reason to allow downloading in demo mode
      GET: 'block',
    },
    '^/admin/voc-admin/api/voc_admin\\.VocAdmin/GetAssignments$': {
      POST: 'pass',
    },
    '^/admin/voc-admin/api/voc_admin\\.VocAdmin/GetCheckIns$': {
      POST: 'pass',
    },
    '^/api/carbon-reporting/external/v1_0/reduction/\\?.*$': { GET: 'pass' },
    '^/api/cashdash/v0.1/[0-9]+/or-details/\\?.*': { GET: 'pass' },
    '^/api/cashdash/v0.1/[0-9]+/overview/\\?.*$': { GET: 'pass' },
    '^/api/cashdash/v0.1/[0-9]+/overview/last-payment/\\?.*$': {
      GET: 'pass',
    },
    '^/api/v0.1/portfolio/[0-9]+/currencies$': {
      GET: 'pass',
    },
    '^/api/cashdash/v0.1/[0-9]+/overview/value-by-month/\\?.*$': {
      GET: 'pass',
    },
    '^/api/feature-flags/v0.1/all-flags$': { GET: 'pass' },
    '^/api/feature-flags/v0.1/update-context-flags$': { GET: 'pass' },
    '^/api/portfolio-dash/[0-9]+/overview\\??.*$': { GET: 'pass' },
    '^/api/portfolio-dash/[0-9]+/programs\\??.*$': { GET: 'pass' },
    '^/api/portfolio-dash/[0-9]+/programs/[0-9]+$': { GET: 'pass' },
    '^/api/portfolio-dash/[0-9]+/registrations/kw-by-month\\??.*$': {
      GET: 'pass',
    },
    '^/api/v0.1/portfolio/[0-9]+/registrations/metadata\\?*.*$': {
      GET: {
        res: {
          _swap: [
            {
              field: 'portfolio_name',
              type: 'portfolio',
            },
          ],
        },
      },
    },
    '^/api/portfolio-dash/[0-9]+/sites/additional-table-data\\??.*$': {
      GET: 'pass',
    },
    '^/api/v0.1/baseline\\?.*$': { GET: 'pass' },
    '^/api/v0.1/track/$': { POST: 'block' },
    '^/api/portfolio-dash/[0-9]+/site-contacts/site/[0-9]+/contact\\??.*$': {
      POST: 'block',
    },
    '^/api/portfolio-dash/[0-9]+/site-contacts/site/[0-9]+/contact/[0-9]+$': {
      POST: 'block',
      GET: {
        res: {
          _swap: [
            {
              field: 'first_name',
              type: 'first_name',
            },
            {
              field: 'last_name',
              type: 'last_name',
            },
            {
              field: 'full_name',
              type: 'full_name',
            },
            {
              field: 'email',
              type: 'email',
            },
            {
              field: 'phone',
              type: 'phone',
            },
            {
              field: 'landline_phone',
              type: 'phone',
            },
            {
              field: 'robodial_phone',
              type: 'phone',
            },
          ],
        },
      },
    },
    '^/api/v0.1/program-reporting/peak-saver/metadata': {
      GET: {
        res: {
          portfolios: {
            _swap: [
              {
                field: 'name',
                type: 'portfolio_id',
                key: 'portfolio_id',
              },
            ],
            '^facilities$': {
              _swap: [
                {
                  field: 'name',
                  type: 'facility_id',
                  key: 'id',
                },
              ],
            },
          },
        },
      },
    },
    '^/api/v0.1/program-reporting/peak-saver/details\\?.*$': {
      GET: {
        res: {
          facility_information: {
            _swap: [
              {
                field: 'name',
                type: 'facility_id',
                key: 'id',
              },
            ],
          },
        },
      },
    },
    '^/api/v0.1/events\\?.*$': { GET: 'pass' },
    '^/api/v0.1/has-active-event/$': { GET: 'pass' },
    '^/api/v0.1/portfolio/[0-9]+/earliest-performance$': { GET: 'pass' },
    '^/api/v0.1/iso/search\\?*.*$': { GET: 'pass' },
    '^/api/v0.1/powerbox/category$': { GET: 'pass' },
    '^/api/v0.1/portfolio/[0-9]+/feature/carbon$': { GET: 'pass' },
    '^/api/v0.1/weather/timeline\\?.*$': { GET: 'pass' },
    '^/api/v0.2/dashboard/iso/peaks/historical\\?.*$': { GET: 'pass' },
    '^/api/v0.2/dashboard/iso/peaks/to-date/[^/]+$': { GET: 'pass' },
    '^/auth/permissions$': { GET: 'pass' },
    '^/earnings/[0-9]+/dispatches/\\d{4}-\\d{2}-\\d{2}/\\d{4}-\\d{2}-\\d{2}$': {
      GET: 'pass',
    },
    '^/earnings/[0-9]+/dispatch/[0-9]+$': { GET: 'pass' },
    '^/earnings/[0-9]+/dispatch-site-performance/[0-9]+/[0-9]+$': {
      GET: 'pass',
    },
    '^/earnings/[0-9]+/facility-dispatch/\\d{4}-\\d{2}-\\d{2}/\\d{4}-\\d{2}-\\d{2}$':
      {
        GET: 'pass',
      },
    '^/earnings/[0-9]+/performance/[a-zA-Z0-9]+\\?.*$': { GET: 'pass' },
    '^/markets/program-tree$': { GET: 'pass' },
    '^/api/v0.1/facility-baselines/.*$': {
      GET: {
        res: {
          '^eventFacilityPairs$': {
            '.': {
              _swap: [
                {
                  field: 'name',
                  type: 'facility_salesforce_id',
                  key: 'facility_id',
                },
              ],
            },
          },
        },
      },
    },
    '^/api/v0.1/time-series-data$': {
      POST: {
        res: {
          data_streams: {
            _swap: [
              {
                field: 'display_name',
                type: 'facility_id',
                key: 'source_id',
              },
            ],
          },
        },
      },
    },
    '^/api/v0.2/load-streams.*$': {
      GET: {
        res: {
          '.': {
            '^display$': {
              _swap: [
                {
                  field: 'name',
                  type: 'facility_salesforce_id',
                  key: 'salesforce_id',
                },
              ],
            },
          },
        },
      },
    },
    '^/api/v0.1/load-streams/aggregate.*$': {
      POST: {
        res: {
          '.': {
            '^display$': {
              _swap: [
                {
                  field: 'name',
                  type: 'facility_salesforce_id',
                  key: 'salesforce_id',
                },
              ],
            },
          },
        },
      },
    },
    '^/api/v0.1/load-streams/metadata.*$': {
      POST: {
        res: {
          facilities: {
            '^meters$': {
              _swap: [
                {
                  field: 'name',
                  type: 'meter',
                },
              ],
            },
            _swap: [
              {
                field: 'city',
                type: 'city',
              },
              {
                field: 'state',
                type: 'state_short',
              },
              {
                field: 'street',
                type: 'street',
              },
              {
                field: 'utility_name',
                type: 'organization_id',
                key: 'utility_id',
              },
              {
                field: 'organization_name',
                type: 'organization_id',
                key: 'organization_id',
              },
              {
                field: 'curtailment_plan',
                type: 'sentence',
              },
              {
                field: 'name',
                type: 'facility_id',
                key: 'id',
              },
              {
                field: 'salesforce_id',
                type: 'facility_salesforce_id',
              },
            ],
          },
          meters: {
            _swap: [
              {
                field: 'name',
                type: 'meter',
              },
            ],
          },
          combined_loads: {
            '^facilities$': {
              _swap: [
                {
                  field: 'city',
                  type: 'city',
                },
                {
                  field: 'state',
                  type: 'state_short',
                },
                {
                  field: 'street',
                  type: 'street',
                },
                {
                  field: 'utility_name',
                  type: 'organization_id',
                  key: 'utility_id',
                },
                {
                  field: 'organization_name',
                  type: 'organization_id',
                  key: 'organization_id',
                },
                {
                  field: 'curtailment_plan',
                  type: 'sentence',
                },
                {
                  field: 'name',
                  type: 'facility_id',
                  key: 'id',
                },
                {
                  field: 'salesforce_id',
                  type: 'facility_salesforce_id',
                },
              ],
            },
            _swap: [
              {
                field: 'name',
                type: 'cohort',
                key: 'hash',
              },
            ],
          },
        },
      },
    },
    '^/api/v0.1/combined-load/[a-zA-Z0-9-]+\\?*.*$': {
      DELETE: 'block',
      PATCH: 'block',
    },
    '^/api/v0.1/combined-load\\?*.*$': {
      GET: {
        res: {
          '^combined_loads$': {
            _swap: [
              {
                field: 'name',
                type: 'cohort',
                key: 'hash',
              },
            ],
          },
        },
      },
      POST: 'block',
    },
    '^/admin/voc-admin/api/voc_admin\\.VocAdmin/EventStream$': {
      POST: {
        res: {
          '^event$': {
            '^note$': {
              _swap: [
                {
                  field: 'body',
                  type: 'sentence',
                },
              ],
            },
            '^call$': {
              '^participantsList$': {
                _swap: [
                  {
                    field: 'fullName',
                    type: 'full_name',
                  },
                  {
                    field: 'phoneNumber',
                    type: 'phone',
                  },
                ],
              },
            },
          },
        },
      },
    },
    '^/admin/voc-admin/api/voc_admin\\.VocAdmin/GetFacilityNotes$': {
      POST: {
        res: {
          '^eventsList$': {
            '^note$': {
              _swap: [
                {
                  field: 'body',
                  type: 'sentence',
                },
              ],
            },
            '^call$': {
              '^participantsList$': {
                _swap: [
                  {
                    field: 'fullName',
                    type: 'full_name',
                  },
                  {
                    field: 'phoneNumber',
                    type: 'phone',
                  },
                ],
              },
            },
          },
        },
      },
    },
    '^/admin/voc-admin/api/voc_admin\\.VocAdmin/GetSiteContactNotes$': {
      POST: {
        res: {
          '^eventsList$': {
            '^note': {
              _swap: [
                {
                  field: 'body',
                  type: 'sentence',
                },
              ],
            },
            '^call$': {
              '^participantsList$': {
                _swap: [
                  {
                    field: 'fullName',
                    type: 'full_name',
                  },
                  {
                    field: 'phoneNumber',
                    type: 'phone',
                  },
                ],
              },
            },
          },
        },
      },
    },
    '^/api/cashdash/v0.1/[0-9]+/line-items/\\?.*$': {
      GET: {
        res: {
          '^display_items$': {
            _swap: [
              {
                field: 'description',
                type: 'sentence',
              },
            ],
          },
        },
      },
    },
    '^/api/cashdash/v0.1/[0-9]+/payment/[a-zA-Z0-9-]+/\\?.*$': {
      GET: {
        res: {
          '^payment_overview$': {
            _swap: [
              {
                optional: true,
                field: 'payment_to',
                type: 'organization_salesforce_id',
                key: 'organization_salesforce_id',
              },
              {
                field: 'description',
                type: 'organization',
              },
            ],
          },
        },
      },
    },
    '^/api/portfolio-dash/[0-9]+/sites/[0-9]+\\??.*$': {
      GET: {
        res: {
          _swap: [
            {
              field: 'address_city',
              type: 'city',
            },
            {
              field: 'address_country_short',
              type: 'country_short',
            },
            {
              field: 'address_country_long',
              type: 'country_long',
            },
            {
              field: 'address_state_long',
              type: 'state_long',
            },
            {
              field: 'address_state_short',
              type: 'state_short',
            },
            {
              field: 'address_street',
              type: 'street',
            },
            {
              field: 'address_zip',
              type: 'zip',
            },
            {
              field: 'name',
              type: 'facility_id',
              key: 'id',
            },
          ],
          '^contacts$': {
            _swap: [
              {
                field: 'first_name',
                type: 'first_name',
              },
              {
                field: 'last_name',
                type: 'last_name',
              },
              {
                field: 'full_name',
                type: 'full_name',
              },
              {
                field: 'landline_phone',
                type: 'phone',
              },
              {
                field: 'robodial_phone',
                type: 'phone',
              },
              {
                field: 'phone',
                type: 'phone',
              },
              {
                field: 'email',
                type: 'email',
                key: 'user_id',
              },
            ],
          },
          '^account_managers$': {
            _swap: [
              {
                field: 'full_name',
                type: 'full_name',
                key: 'id',
              },
              {
                field: 'email',
                type: 'email',
                key: 'id',
              },
            ],
          },
        },
      },
    },
    '^/api/portfolio-dash/[0-9]+/site-contacts/site/[0-9]+/add/eligible-users$':
      {
        GET: {
          res: {
            _swap: [
              {
                field: 'first_name',
                type: 'first_name',
              },
              {
                field: 'last_name',
                type: 'last_name',
              },
              {
                field: 'phone',
                type: 'phone',
              },
              {
                field: 'landline_phone',
                type: 'phone',
              },
              {
                field: 'robodial_phone',
                type: 'phone',
              },
              {
                field: 'full_name',
                type: 'full_name',
              },
              {
                field: 'email',
                type: 'email',
              },
            ],
          },
        },
      },
    '^/api/portfolio-dash/[0-9]+/sites\\??.*$': {
      GET: {
        res: {
          _swap: [
            {
              field: 'name',
              type: 'facility_salesforce_id',
              key: 'salesforce_id',
            },
          ],
        },
      },
    },
    '^/api/v0.1/acknowledgments/portfolio/[0-9]+/dispatch/[0-9]+/site/[0-9]+$':
      {
        GET: {
          res: {
            _swap: [
              {
                field: 'site_name',
                type: 'facility_id',
                key: 'facility_id',
              },
            ],
            '^acknowledgments$': {
              _swap: [
                {
                  field: 'email',
                  type: 'email',
                },
                {
                  field: 'first_name',
                  type: 'first_name',
                },
                {
                  field: 'full_name',
                  type: 'full_name',
                },
                {
                  field: 'last_name',
                  type: 'last_name',
                },
                {
                  field: 'landline_phone',
                  type: 'phone',
                },
                {
                  field: 'phone',
                  type: 'phone',
                },
                {
                  field: 'robodial_phone',
                  type: 'phone',
                },
              ],
            },
          },
        },
      },
    '^/api/v0.1/facility-info/[0-9]+$': {
      GET: {
        res: {
          _swap: [
            {
              field: 'address_line',
              type: 'street',
            },
            {
              field: 'city',
              type: 'city',
            },
            {
              field: 'country',
              type: 'country_short',
            },
            {
              field: 'state_long',
              type: 'state_long',
            },
            {
              field: 'state_short',
              type: 'state_short',
            },
            {
              field: 'zip',
              type: 'zip',
            },
          ],
        },
      },
    },
    '^/api/v0.1/facility/search\\?.*$': {
      GET: {
        req: handleFacilitySearch,
        res: handleFacilitySearchRes,
      },
    },
    '^/api/v0.2/dashboard/series$': {
      GET: {
        res: {
          '^facilities$': {
            _swap: [
              {
                field: 'city',
                type: 'city',
              },
              {
                field: 'country',
                type: 'country_long',
              },
              {
                field: 'name',
                type: 'facility_salesforce_id',
                key: 'salesforce_id',
              },
              {
                field: 'organization_name',
                type: 'organization_id',
                key: 'organization_id',
              },
              {
                field: 'state',
                type: 'state_long',
              },
            ],
            '^meters$': {
              _swap: [
                {
                  field: 'name',
                  type: 'meter',
                },
              ],
            },
          },
        },
      },
    },
    '^/api/voc/snapshots/dispatches(\\?.*)?$': {
      GET: {
        res: {
          '^snapshots$': {
            '^portfolios$': {
              '^[0-9]+$': {
                '^metadata$': {
                  _swap: [
                    {
                      field: 'name',
                      type: 'portfolio_id',
                      key: 'portfolio_id',
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
    '^/api/voc/snapshots/portfolio-details(\\?.*)?$': {
      GET: {
        res: {
          '^portfolio_details_snapshot$': {
            '^facilities$': {
              '^[0-9]+$': {
                '^metadata$': {
                  _swap: [
                    {
                      field: 'name',
                      type: 'facility',
                    },
                    {
                      field: 'curtailment_plan',
                      type: 'sentence',
                    },
                  ],
                  '^organization$': {
                    _swap: [
                      {
                        field: 'name',
                        type: 'organization_id',
                        key: 'organization_id',
                      },
                    ],
                  },
                  '^acknowledgments$': {
                    _swap: [
                      {
                        field: 'full_name',
                        type: 'full_name',
                      },
                      {
                        field: 'email',
                        type: 'email',
                      },
                      {
                        field: 'landline',
                        type: 'phone',
                      },
                      {
                        field: 'phone',
                        type: 'phone',
                      },
                    ],
                  },
                  '^applications$': {
                    _swap: [
                      {
                        field: 'organization_name',
                        type: 'organization_id',
                        key: 'organization_name',
                      },
                    ],
                  },
                },
              },
            },
          },
        },
      },
    },
    '^/earnings/[0-9]+/facilities/\\d{4}-\\d{2}-\\d{2}/\\d{4}-\\d{2}-\\d{2}': {
      GET: {
        res: {
          '^facilities$': {
            '^[0-9]+$': {
              _swap: [
                {
                  field: 'facility_name',
                  type: 'facility_id',
                  key: '_key',
                },
              ],
            },
          },
        },
      },
    },
    '^/markets/organizations\\?include=members.*$': {
      GET: {
        res: {
          '^[0-9]+$': {
            _swap: [
              {
                field: 'name',
                type: 'organization_id',
                key: 'organization_id',
              },
            ],
            '^members$': {
              _swap: [
                {
                  field: 'email',
                  type: 'email',
                },
                {
                  field: 'first_name',
                  type: 'first_name',
                },
                {
                  field: 'full_name',
                  type: 'full_name',
                },
                {
                  field: 'landline_phone',
                  type: 'phone',
                },
                {
                  field: 'last_name',
                  type: 'last_name',
                },
                {
                  field: 'phone',
                  type: 'phone',
                },
                {
                  field: 'robodial_phone',
                  type: 'phone',
                },
              ],
            },
          },
        },
      },
    },
    '^/markets/voc/scheduled-events/[0-9]+\\?portfolio_id=[0-9]+$': {
      GET: {
        res: {
          '^facilities$': {
            _swap: [
              {
                field: 'name',
                type: 'facility_salesforce_id',
                key: 'salesforce_id',
              },
            ],
            '^acknowledgments$': {
              _swap: [
                {
                  field: 'full_name',
                  type: 'full_name',
                },
                {
                  field: 'email',
                  type: 'email',
                },
                {
                  field: 'landline',
                  type: 'phone',
                },
                {
                  field: 'phone',
                  type: 'phone',
                },
              ],
            },
            '^organization$': {
              _swap: [
                {
                  field: 'name',
                  type: 'organization_id',
                  key: 'id',
                },
              ],
            },
          },
          '^facility_groups$': {
            _swap: [
              {
                field: 'name',
                type: 'facility_salesforce_id',
                key: 'salesforce_id',
              },
            ],
            '^acknowledgments$': {
              _swap: [
                {
                  field: 'full_name',
                  type: 'full_name',
                },
                {
                  field: 'email',
                  type: 'email',
                },
                {
                  field: 'landline',
                  type: 'phone',
                },
                {
                  field: 'phone',
                  type: 'phone',
                },
              ],
            },
            '^organization$': {
              _swap: [
                {
                  field: 'name',
                  type: 'organization_id',
                  key: 'id',
                },
              ],
            },
          },
        },
      },
    },
    '^/portfolio/user.*$': {
      GET: {
        res: {
          '^access_to$': {
            _swap: [
              {
                field: 'name',
                type: 'portfolio_id',
                key: 'portfolio_id',
              },
            ],
          },
          '^member_of$': {
            _swap: [
              {
                field: 'name',
                type: 'portfolio_id',
                key: 'portfolio_id',
              },
            ],
          },
        },
      },
    },
    '^/profile/user$': {
      GET: {
        res: {
          '^organization$': {
            _swap: [
              {
                field: 'name',
                type: 'organization_id',
                key: 'organization_id',
              },
            ],
          },
          '^facilities$': {
            _swap: [
              {
                field: 'name',
                type: 'facility_salesforce_id',
                key: 'site_salesforce_id',
              },
            ],
          },
        },
      },
    },
    '^/api/v0.1/powerbox/filter\\?.*$': {
      GET: {
        res: handlePowerboxFilters,
      },
    },
    '^/ledger/cashdash/date-filter-start-date\\?.*$': {
      GET: 'pass',
    },
    '^/ledger/cashdash/payments/summary\\?.*$': {
      GET: {
        res: {
          '^payments$': {
            '^facility_names$': {
              _swap: [
                {
                  field: null,
                  type: 'facility',
                },
              ],
            },
            '^program_names$': {
              _swap: [
                {
                  field: null,
                  type: 'program',
                },
              ],
            },
            _swap: [
              {
                field: 'payment_account_name',
                type: 'bank_name',
              },
            ],
          },
        },
      },
    },
  },
  replacement: {
    generic: {
      city: 'DEMO San Francisco',
      state_long: 'DEMO California',
      state_short: 'DEMO CA',
      country_short: 'DEMO US',
      country_long: 'DEMO United States',
      street: 'DEMO 123 Voltus Way',
      zip: 'DEMO 10203',
      phone: 'DEMO +10000000000',
      first_name: 'DEMO John',
      last_name: 'DEMO Doe',
      full_name: 'DEMO John Doe',
      email: 'DEMO jdoe@voltus.co',
      meter: 'DEMO meter',
      portfolio: 'DEMO Portfolio',
      facility: 'DEMO Facility',
      organization: 'DEMO Organization',
      utility: 'Demo Utility',
      sentence:
        'DEMO Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
      cohort: 'Demo Cohort',
      bank_name: 'DEMO Bank',
      program: 'DEMO Program',
    },
    production: {
      user: {
        id: {},
        email: {},
      },
      organization: {
        id: {
          '7': 'DEMO Aggregate Plants',
          '1867749': 'DEMO Agriculture & Farming',
          '1': 'DEMO All',
          '2995299': 'DEMO Banking & Finance',
          '502': 'DEMO Big Box Retailer #1',
          '24': 'DEMO Big Box Retailer #2',
          '513': 'DEMO Chemical Processing',
          '477': 'DEMO Cold Storage',
          '476': 'DEMO Commercial Real Estate',
          '846954': 'DEMO Construction',
          '2443940': 'DEMO Controls Partner',
          '1216264': 'DEMO Cryptomining',
          '744074': 'DEMO Cryptomining Co. #2',
          '3669434': 'DEMO Data Center',
          '2301749': 'DEMO Distribution & Shipping',
          '5285384': 'DEMO EV Bus Charging Depot',
          '6691': 'DEMO Food & Beverage Processing',
          '2728584': 'DEMO Hospital',
          '390419': 'DEMO K-12 School District',
          '1936874': 'DEMO Lumber',
          '1006827': 'DEMO Manufacturing - Automotive',
          '1230611': 'DEMO Manufacturing - CP&G',
          '532244': 'DEMO Manufacturing - Machinery',
          '548': 'DEMO Manufacturing - Metals #2',
          '2716802': 'DEMO Manufacturing - Plastics',
          '127931': 'DEMO Materials Aggregates',
          '3241070': 'DEMO Mining',
          '2861194': 'DEMO Municipal Government',
          '19765568': 'DEMO Government - State',
          '2336616': 'DEMO Oil & Gas',
          '2992593': 'DEMO Paper Mill & Printing',
          '1730933': 'DEMO Pharmaceutical',
          '492': 'DEMO Product Packaging',
          '3690099': 'DEMO R&D Laboratory',
          '3710593': 'DEMO Residential Aggregator #1',
          '3710685': 'DEMO Residential Aggregator #2',
          '3996003': 'DEMO Residential Complex',
          '2215927': 'DEMO Shopping Center',
          '8464964': 'DEMO Southwest Power Pool VPP',
          '3717100': 'DEMO Sports Stadium',
          '1056113': 'DEMO University #1',
          '3685963': 'DEMO University #2',
          '3838': 'DEMO University #3',
          '93036': 'DEMO Waste & Water Treatment',
        },
        salesforceId: {
          '0015000001HfRh0AAF': 'DEMO Aggregate Plants',
          '0012J00002PI5UBQA1': 'DEMO Agriculture & Farming',
          voltus: 'DEMO All',
          '0012J00002MuuNJQAZ': 'DEMO Banking & Finance',
          '0015000001FLWsRAAX': 'DEMO Big Box Retailer #1',
          '0015000001FLI1KAAX': 'DEMO Big Box Retailer #2',
          '0015000001FXPavAAH': 'DEMO Chemical Processing',
          '0015000001P1jjSAAR': 'DEMO Cold Storage',
          '0015000001UVbR0AAL': 'DEMO Commercial Real Estate',
          '0012J00002MvNgiQAF': 'DEMO Construction',
          '0012J00002TKUBcQAP': 'DEMO Controls Partner',
          '0012J00002OqjE7QAJ': 'DEMO Cryptomining',
          '0012J00002MxrymQAB': 'DEMO Cryptomining Co. #2',
          '0012J00002WEzYnQAL': 'DEMO Data Center',
          '0012J00002MucKOQAZ': 'DEMO Distribution & Shipping',
          '0012J00002ZDRwkQAH': 'DEMO EV Bus Charging Depot',
          '0015000001G7ALGAA3': 'DEMO Food & Beverage Processing',
          '0012J00002TJ4qcQAD': 'DEMO Hospital',
          '0012J00002LlDK8QAN': 'DEMO K-12 School District',
          '0012J00002RcmbxQAB': 'DEMO Lumber',
          '0012J00002Kwl9NQAR': 'DEMO Manufacturing - Automotive',
          '0012J00002PP7LuQAL': 'DEMO Manufacturing - CP&G',
          '0012J00002MuTDiQAN': 'DEMO Manufacturing - Machinery',
          '0015000001IGqPwAAL': 'DEMO Manufacturing - Metals #2',
          '0012J00002EmqIUQAZ': 'DEMO Manufacturing - Plastics',
          '0012J00002JmkJ0QAJ': 'DEMO Materials Aggregates',
          '0012J00002ZAnPTQA1': 'DEMO Mining',
          '0012J00002QQOMmQAP': 'DEMO Municipal Government',
          '00150000025bQvvAAE': 'DEMO Government - State',
          '0015000001TV0qNAAT': 'DEMO Oil & Gas',
          '0012J00002WsVxQQAV': 'DEMO Paper Mill & Printing',
          '0015000001VRvxaAAD': 'DEMO Pharmaceutical',
          '0015000001FL3XEAA1': 'DEMO Product Packaging',
          '0012J00002ZBQpOQAX': 'DEMO R&D Laboratory',
          '0012J00002TJdnnQAD': 'DEMO Residential Aggregator #1',
          '0012J00002WrqKHQAZ': 'DEMO Residential Aggregator #2',
          '0012J00002ZJQjgQAH': 'DEMO Residential Complex',
          '0012J00002THYmQQAX': 'DEMO Shopping Center',
          '0012J00002aNufZQAS': 'DEMO Southwest Power Pool VPP',
          '0012J00002OoAuoQAF': 'DEMO Sports Stadium',
          '0012J00002MUieUQAT': 'DEMO University #1',
          '0012J00002MSlLoQAL': 'DEMO University #2',
          '0015000001Xql7uAAB': 'DEMO University #3',
          '0012J00002JlM6NQAV': 'DEMO Waste & Water Treatment',
        },
      },
      portfolio: {
        id: {
          '107': 'DEMO Aggregate Plants',
          '3234': 'DEMO Agriculture & Farming',
          '1': 'DEMO All',
          '3545': 'DEMO Banking & Finance',
          '95': 'DEMO Big Box Retailer #1',
          '93': 'DEMO Big Box Retailer #2',
          '41': 'DEMO Chemical Processing',
          '144': 'DEMO Cold Storage',
          '20': 'DEMO Commercial Real Estate',
          '2720': 'DEMO Construction',
          '3392': 'DEMO Controls Partner',
          '3026': 'DEMO Cryptomining',
          '2619': 'DEMO Cryptomining Co. #2',
          '3785': 'DEMO Data Center',
          '3347': 'DEMO Distribution & Shipping',
          '4149': 'DEMO EV Bus Charging Depot',
          '44': 'DEMO Food & Beverage Processing',
          '3447': 'DEMO Hospital',
          '2460': 'DEMO K-12 School District',
          '3245': 'DEMO Lumber',
          '2846': 'DEMO Manufacturing - Automotive',
          '3030': 'DEMO Manufacturing - CP&G',
          '2519': 'DEMO Manufacturing - Machinery',
          '115': 'DEMO Manufacturing - Metals #2',
          '3445': 'DEMO Manufacturing - Plastics',
          '2370': 'DEMO Materials Aggregates',
          '3613': 'DEMO Mining',
          '3484': 'DEMO Municipal Government',
          '5083': 'DEMO Government - State',
          '3358': 'DEMO Oil & Gas',
          '3543': 'DEMO Paper Mill & Printing',
          '3197': 'DEMO Pharmaceutical',
          '86': 'DEMO Product Packaging',
          '3795': 'DEMO R&D Laboratory',
          '3803': 'DEMO Residential Aggregator #1',
          '3804': 'DEMO Residential Aggregator #2',
          '3968': 'DEMO Residential Complex',
          '3319': 'DEMO Shopping Center',
          '4330': 'DEMO Southwest Power Pool VPP',
          '3810': 'DEMO Sports Stadium',
          '2881': 'DEMO University #1',
          '3791': 'DEMO University #2',
          '214': 'DEMO University #3',
          '2352': 'DEMO Waste & Water Treatment',
        },
      },
      facility: {
        id: {
          '1910': 'DEMO 159 Bleecker St',
          '120263': 'DEMO 175 Varick St',
          '1192264': 'DEMO 4 School St',
          '1913': 'DEMO 57 E Ilth St',
          '1192262': 'DEMO 5 School St',
          '1192263': 'DEMO 8 Education Ave.',
          '309330': 'DEMO All Day Co. #1',
          '309332': 'DEMO All Day Co. #2',
          '309333': 'DEMO All Day Co. #3',
          '309345': 'DEMO All Day Co. #4',
          '190078': 'DEMO All Day Co. #5',
          '15602': 'DEMO All Day Co. #6',
          '388274': 'DEMO Aluminum Smelter',
          '1118520': 'DEMO Big Box Store 1',
          '1192269': 'DEMO Big Box Store 2',
          '958695': 'DEMO Business School',
          '2247024': 'DEMO Cold Storage Facility',
          '1871495': 'DEMO DC4',
          '1871497': 'DEMO DC5',
          '776912': 'DEMO DC6',
          '232451': 'DEMO Digital Equipment Corporation',
          '7547069': 'DEMO East Campus',
          '2261236': 'DEMO GravelCo',
          '7184138': 'DEMO Main Campus',
          '22139': 'DEMO Main Campus',
          '178297': 'DEMO Metals Site 1',
          '178295': 'DEMO Metals Site 2',
          '178296': 'DEMO Metals Site 3',
          '4551892': 'DEMO Metropolitan Health System',
          '3481590': 'DEMO Midstream Facility #1',
          '3481594': 'DEMO Midstream Facility #2',
          '3481589': 'DEMO Midstream Facility #3',
          '855450': 'DEMO Midwest Mining',
          '1437619': 'DEMO Mine 1',
          '1437620': 'DEMO Mine 2',
          '7': 'DEMO Rocks Inc.',
          '20208600': 'DEMO Southwest Power Pool VPP',
          '22136': 'DEMO Sports Center',
          '1006899': 'DEMO Steel Mill',
          '7184139': 'DEMO West Campus',
          '11733274': 'DEMO EV Bus Charging Site',
        },
        salesforceId: {
          a005000000mkHw7AAE: 'DEMO 159 Bleecker St',
          a005000000vAB7VAAW: 'DEMO 175 Varick St',
          a002J000010LWsuQAG: 'DEMO 4 School St',
          a005000000mkHwqAAE: 'DEMO 57 E Ilth St',
          a002J000010LWkcQAG: 'DEMO 5 School St',
          a002J000010LWpcQAG: 'DEMO 8 Education Ave.',
          a002J00000xodyPQAQ: 'DEMO All Day Co. #1',
          a002J00000xoe2lQAA: 'DEMO All Day Co. #2',
          a002J00000xoe2mQAA: 'DEMO All Day Co. #3',
          a002J00000xoiGPQAY: 'DEMO All Day Co. #4',
          a002J00000y6zwzQAA: 'DEMO All Day Co. #5',
          a005000000sJX4RAAW: 'DEMO All Day Co. #6',
          a002J00000ydf6BQAQ: 'DEMO Aluminum Smelter',
          a002J00000zonxwQAA: 'DEMO Big Box Store 1',
          a002J000010UDFpQAO: 'DEMO Big Box Store 2',
          a002J000010BKQfQAO: 'DEMO Business School',
          a002J000011e2hcQAA: 'DEMO Cold Storage Facility',
          a002J000011DRz1QAG: 'DEMO DC4',
          a002J000011DVSAQA4: 'DEMO DC5',
          a002J00000zowAlQAI: 'DEMO DC6',
          a002J00000yLHsDQAW: 'DEMO Digital Equipment Corporation',
          a002J000014DB2ZQAW: 'DEMO East Campus',
          a005000000l9D7vAAE: 'DEMO GravelCo',
          a002J000012pUwZQAU: 'DEMO Main Campus',
          a005000000s0p8UAAQ: 'DEMO Main Campus',
          a002J00000y4HuWQAU: 'DEMO Metals Site 1',
          a002J00000y4HubQAE: 'DEMO Metals Site 2',
          a002J00000y4HugQAE: 'DEMO Metals Site 3',
          a002J000012krLmQAI: 'DEMO Metropolitan Health System',
          a002J000011MhlqQAC: 'DEMO Midstream Facility #1',
          a002J000011MhqTQAS: 'DEMO Midstream Facility #2',
          a002J000011MhjbQAC: 'DEMO Midstream Facility #3',
          a002J00001016MMQAY: 'DEMO Midwest Mining',
          a002J000010p63AQAQ: 'DEMO Mine 1',
          a002J000010p68dQAA: 'DEMO Mine 2',
          a005000000hzz0HAAQ: 'DEMO Rocks Inc.',
          a002J000015GxeVQAS: 'DEMO Southwest Power Pool VPP',
          a005000000s0p8FAAQ: 'DEMO Sports Center',
          a002J000010HdHhQAK: 'DEMO Steel Mill',
          a002J000012pUxIQAU: 'DEMO West Campus',
          a002J000014rZYyQAM: 'DEMO EV Bus Charging Site',
        },
      },
      cohort: {
        id: {},
      },
    },
    development: {
      user: {
        id: {},
        email: {},
      },
      organization: {
        id: {
          '134': 'DEMO Aggregate Plants',
          '286086': 'DEMO Agriculture & Farming',
          '74': 'DEMO All',
          '104': 'DEMO Big Box Retailer #1',
          '102': 'DEMO Big Box Retailer #2',
          '115': 'DEMO Chemical Processing',
          '190': 'DEMO Cold Storage',
          '222': 'DEMO Commercial Real Estate',
          '2443940': 'DEMO Controls Partner',
          '174535': 'DEMO Cryptomining',
          '112028': 'DEMO Cryptomining Co. #2',
          '985': 'DEMO Food & Beverage Processing',
          '75512': 'DEMO K-12 School District',
          '286268': 'DEMO Lumber',
          '143860': 'DEMO Manufacturing - Automotive',
          '177491': 'DEMO Manufacturing - CP&G',
          '111984': 'DEMO Manufacturing - Machinery',
          '44449': 'DEMO Materials Aggregates',
          '286674': 'DEMO Pharmaceutical',
          '3710593': 'DEMO Residential Aggregator #1',
          '287058': 'DEMO Residential Aggregator #2',
          '292143': 'DEMO Shopping Center',
          '154876': 'DEMO University #1',
          '44441': 'DEMO Waste & Water Treatment',
        },
        salesforceId: {
          '0015000001HfRh0AAF': 'DEMO Aggregate Plants',
          '0012J00002PI5UBQA1': 'DEMO Agriculture & Farming',
          voltus: 'DEMO All',
          '0015000001FLWsRAAX': 'DEMO Big Box Retailer #1',
          '0015000001FLI1KAAX': 'DEMO Big Box Retailer #2',
          '0015000001FXPavAAH': 'DEMO Chemical Processing',
          '0015000001P1jjSAAR': 'DEMO Cold Storage',
          '0015000001UVbR0AAL': 'DEMO Commercial Real Estate',
          '0012J00002TKUBcQAP': 'DEMO Controls Partner',
          '0012J00002OqjE7QAJ': 'DEMO Cryptomining',
          '0012J00002MxrymQAB': 'DEMO Cryptomining Co. #2',
          '0015000001G7ALGAA3': 'DEMO Food & Beverage Processing',
          '0012J00002LlDK8QAN': 'DEMO K-12 School District',
          '0012J00002RcmbxQAB': 'DEMO Lumber',
          '0012J00002Kwl9NQAR': 'DEMO Manufacturing - Automotive',
          '0012J00002PP7LuQAL': 'DEMO Manufacturing - CP&G',
          '0012J00002MuTDiQAN': 'DEMO Manufacturing - Machinery',
          '0012J00002JmkJ0QAJ': 'DEMO Materials Aggregates',
          '0015000001VRvxaAAD': 'DEMO Pharmaceutical',
          '0012J00002TJdnnQAD': 'DEMO Residential Aggregator #1',
          '0012J00002WrqKHQAZ': 'DEMO Residential Aggregator #2',
          '001S000001dLBPFIA4': 'DEMO Shopping Center',
          '0012J00002MUieUQAT': 'DEMO University #1',
          '0012J00002JlM6NQAV': 'DEMO Waste & Water Treatment',
        },
      },
      portfolio: {
        id: {
          '80': 'DEMO Aggregate Plants',
          '6598': 'DEMO Agriculture & Farming',
          '23': 'DEMO All',
          '51': 'DEMO Big Box Retailer #1',
          '49': 'DEMO Big Box Retailer #2',
          '62': 'DEMO Chemical Processing',
          '193': 'DEMO Commercial Real Estate',
          '137': 'DEMO Cold Storage',
          '3392': 'DEMO Controls Partner',
          '6329': 'DEMO Cryptomining',
          '2860': 'DEMO Cryptomining Co. #2',
          '474': 'DEMO Food & Beverage Processing',
          '2723': 'DEMO K-12 School District',
          '6712': 'DEMO Lumber',
          '6169': 'DEMO Manufacturing - Automotive',
          '6348': 'DEMO Manufacturing- CP&G',
          '2816': 'DEMO Manufacturing - Machinery',
          '2603': 'DEMO Materials Aggregates',
          '6470': 'DEMO Pharmaceutical',
          '3803': 'DEMO Residential Aggregator #1',
          '6381': 'DEMO Residential Aggregator #2',
          '6764': 'DEMO Shopping Center',
          '6220': 'DEMO University #1',
          '2600': 'DEMO Waste & Water Treatment',
        },
      },
      facility: {
        id: {
          '14169': 'DEMO 159 Bleecker St',
          '25989': 'DEMO 175 Varick St',
          '205774': 'DEMO 4 School St',
          '14170': 'DEMO 57 E Ilth St',
          '205772': 'DEMO 5 School St',
          '205773': 'DEMO 8 Education Ave.',
          '108189': 'DEMO All Day Co. #1',
          '108191': 'DEMO All Day Co. #2',
          '108912': 'DEMO All Day Co. #3',
          '108204': 'DEMO All Day Co. #4',
          '84773': 'DEMO All Day Co. #5',
          '14747': 'DEMO All Day Co. #6',
          '121462': 'DEMO Aluminum Smelter',
          '192839': 'DEMO Big Box Store 1',
          '205779': 'DEMO Big Box Store 2',
          '170895': 'DEMO Business School',
          '299263': 'DEMO Cold Storage Facility',
          '299246': 'DEMO DC4',
          '299249': 'DEMO DC5',
          '153139': 'DEMO DC6',
          '95359': 'DEMO Digital Equipment Corporation',
          '232658': 'DEMO GravelCo',
          '14445': 'DEMO Main Campus',
          '84724': 'DEMO Metals Site 1',
          '84722': 'DEMO Metals Site 2',
          '84723': 'DEMO Metals Site 3',
          '168072': 'DEMO Midwest Mining',
          '231736': 'DEMO Mine 1',
          '231737': 'DEMO Mine 2',
          '1248': 'DEMO Rocks Inc.',
          '14442': 'DEMO Sports Center',
          '170973': 'DEMO Steel Mill',
        },
        salesforceId: {
          a005000000mkHw7AAE: 'DEMO 159 Bleecker St',
          a005000000vAB7VAAW: 'DEMO 175 Varick St',
          a002J000010LWsuQAG: 'DEMO 4 School St',
          a005000000mkHwqAAE: 'DEMO 57 E Ilth St',
          a002J000010LWkcQAG: 'DEMO 5 School St',
          a002J000010LWpcQAG: 'DEMO 8 Education Ave.',
          a002J00000xodyPQAQ: 'DEMO All Day Co. #1',
          a002J00000xoe2lQAA: 'DEMO All Day Co. #2',
          a002J00000xoe2mQAA: 'DEMO All Day Co. #3',
          a002J00000xoiGPQAY: 'DEMO All Day Co. #4',
          a002J00000y6zwzQAA: 'DEMO All Day Co. #5',
          a005000000sJX4RAAW: 'DEMO All Day Co. #6',
          a002J00000ydf6BQAQ: 'DEMO Aluminum Smelter',
          a002J00000zonxwQAA: 'DEMO Big Box Store 1',
          a002J000010UDFpQAO: 'DEMO Big Box Store 2',
          a002J000010BKQfQAO: 'DEMO Business School',
          a002J000011e2hcQAA: 'DEMO Cold Storage Facility',
          a002J000011DRz1QAG: 'DEMO DC4',
          a002J000011DVSAQA4: 'DEMO DC5',
          a002J00000zowAlQAI: 'DEMO DC6',
          a002J00000yLHsDQAW: 'DEMO Digital Equipment Corporation',
          a005000000l9D7vAAE: 'DEMO GravelCo',
          a005000000s0p8UAAQ: 'DEMO Main Campus',
          a002J00000y4HuWQAU: 'DEMO Metals Site 1',
          a002J00000y4HubQAE: 'DEMO Metals Site 2',
          a002J00000y4HugQAE: 'DEMO Metals Site 3',
          a002J00001016MMQAY: 'DEMO Midwest Mining',
          a002J000010p63AQAQ: 'DEMO Mine 1',
          a002J000010p68dQAA: 'DEMO Mine 2',
          a005000000hzz0HAAQ: 'DEMO Rocks Inc.',
          a005000000s0p8FAAQ: 'DEMO Sports Center',
          a002J000010HdHhQAK: 'DEMO Steel Mill',
        },
      },
      cohort: {
        id: {},
      },
    },
  },
}
