import * as React from 'react'
import { connect } from 'react-redux'
import { Navigate, matchPath } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { TEAM } from '@voltus/constants'
import { NotFound, ProtectedRoute } from '@voltus/core-components'
import { FlagEnabledRoute, Flags, ldclient } from '@voltus/feature-flags'
import { selectPermissions } from '@voltus/modules'
import { Permissions, RouteConfig, Routes } from '@voltus/types'

import { userCan, filterRoutesForDemo } from '@voltus/utils'
import { PATHS } from '../constants/routes'

const BACKGROUND_COLORS_BY_PATH = {
  [PATHS.ACTIVE_DISPATCHES_ADMIN]: 'background.admin',
  [`${PATHS.REPORTS_PEAK_SAVER_ROOT}/*`]: 'background.primary',
}

const MAX_TRIES = 10
// This is a temporary solution to handle the situation where want to check a feature
// flag _before_ any component has loaded for a route. This is a _pretty_ hacky but since
// our feature flag client is initialized asynchronously, we need a way to wait for its
// initialization to complete before deciding which component will be used the route.
// So this function will check a few times on an interval if the launch darkly client
// is ready, and when it is, it will resolve. If the ld client takes too long initialize,
// then we resolve anyway as to not block rendering. In that case the old page will be shown.
const checkLDClient = async () => {
  return new Promise((resolve) => {
    let tries = 0
    if (ldclient) {
      resolve(true)
      return
    }

    const intervalId = setInterval(() => {
      tries++
      if (ldclient) {
        clearInterval(intervalId)
        resolve(true)
        return
      }
      if (tries > MAX_TRIES) {
        clearInterval(intervalId)
        resolve(false)
      }
    }, 50)
  })
}

export const getBackgroundColorByRoute = (pathname: string): string => {
  const found = Object.entries(BACKGROUND_COLORS_BY_PATH)
    .filter(([path]) =>
      matchPath(
        {
          path,
        },
        pathname
      )
    )
    .map((res) => res[1])
  if (found.length) {
    return found[0]
  }
  return 'background.secondary'
}

const IndexRoute = connect(
  createStructuredSelector({
    permissions: selectPermissions,
  })
)(({ permissions }: { permissions: Permissions }) => {
  const homepage = permissions.viewEarnings
    ? PATHS.PORTFOLIO_HOME
    : PATHS.REALTIME_ENERGY
  return <Navigate to={homepage} />
})

export const routes: Routes = {
  routes: [],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initRoutes: function (this: Routes, store: any) {
    const BankAccountSetup = React.lazy(
      () =>
        import(
          /* webpackChunkName: "bank-account-setup" */ './bankAccountSetup'
        )
    )

    const Reports = React.lazy(
      () => import(/* webpackChunkName: "reports" */ './reports')
    )

    const AccountManagement = React.lazy(
      () => import(/* webpackChunkName: "profile" */ './accountManagement')
    )
    const FeatureFlagsAdminPanel = React.lazy(
      () =>
        import(
          /* webpackChunkName: "flags-admin" */ '@voltus/feature-flags/FeatureFlagAdminPanel'
        )
    )

    // It's complaining because the two different lazy components
    // have different signatures - one is () => JSX.Element while the
    // other is a redux connected component. It doesn't really
    // matter because they both work independetly, typescript just
    // gets confused when the method can return either/or and their
    // types differ slightly. So disabling since this is a temporary
    // patch while we migrate to the newer pattern
    // eslint-disable-next-line
    // @ts-ignore
    const Portfolio = React.lazy(async () => {
      await checkLDClient()
      if (ldclient?.waitUntilReady) {
        await ldclient.waitUntilReady()
      }
      if (ldclient?.variation('portfolio-zustand')) {
        return await import(
          /* webpackChunkName: "portfolio" */ './portfolio/Portfolio'
        )
      }

      const inject = await import(
        /* webpackChunkName: "portfolio-inject" */ './portfolio/modules/inject'
      )
      store.injectReducer('portfolio', inject.reducer)
      store.injectSaga('portfolio', inject.saga)
      return await import(/* webpackChunkName: "portfolio" */ './portfolio')
    })

    const Earnings = React.lazy(() =>
      import(
        /* webpackChunkName: "cashdash-inject" */ './earnings/modules/inject'
      ).then((module) => {
        store.injectReducer('earnings', module.reducer)
        return import(/* webpackChunkName: "cashdash" */ './earnings')
      })
    )
    const Boards = React.lazy(
      () => import(/* webpackChunkName: "boards" */ './boards')
    )

    const RealtimeEnergy = React.lazy(() =>
      import(/* webpackChunkName: "realtime-energy" */ './realtimeEnergy').then(
        (module) => {
          module.injectReducer(store)
          return module
        }
      )
    )

    const GetEnabled = React.lazy(
      () => import(/* webpackChunkName: "get-enabled" */ './getEnabled')
    )
    const Dispatches = React.lazy(
      () => import(/* webpackChunkName: "dispatches" */ './dispatches')
    )
    const BuildInfo = React.lazy(
      () => import(/* webpackChunkName: "build-info" */ './buildInfo')
    )
    const TermsAndConditions = React.lazy(
      () =>
        import(
          /* webpackChunkName: "terms-and-conditions" */ './termsAndConditions/TermsAndConditions'
        )
    )

    const routes: Array<RouteConfig> = [
      {
        team: TEAM.FRONTEND,
        path: PATHS.INTERNAL_FLAGS,
        Component: FeatureFlagsAdminPanel,
        predicate: ({ permissions }) =>
          Promise.resolve(permissions?.viewAdmin === true),
        Wrapper: ProtectedRoute,
      },
      {
        team: TEAM.FRONTEND,
        path: PATHS.INTERNAL_BUILD,
        Component: BuildInfo,
        predicate: ({ permissions }) =>
          Promise.resolve(permissions?.viewAdmin === true),
        Wrapper: ProtectedRoute,
      },
      {
        team: TEAM.POD_CX,
        path: `${PATHS.TERMS_AND_CONDITIONS}/*`,
        Component: TermsAndConditions,
        Wrapper: React.Fragment,
      },
      {
        team: TEAM.FRONTEND,
        path: PATHS.INDEX,
        Component: IndexRoute,
        Wrapper: React.Fragment,
      },
      {
        team: TEAM.FRONTEND,
        path: PATHS.HOME,
        Component: IndexRoute,
        Wrapper: React.Fragment,
      },
      {
        team: TEAM.FRONTEND,
        path: PATHS.GET_ENABLED,
        Component: GetEnabled,
        Wrapper: React.Fragment,
      },
      {
        team: TEAM.POD_AF,
        path: `${PATHS.DISPATCHES}/*`,
        predicate: ({ permissions }) =>
          Promise.resolve(permissions?.viewVoc === true),
        Wrapper: ProtectedRoute,
        Component: Dispatches,
      },
      {
        team: TEAM.POD_CX,
        path: `${PATHS.PORTFOLIO_HOME}/*`,
        Component: Portfolio,
        predicate: ({ permissions }) =>
          Promise.resolve(permissions?.viewEarnings === true),
        Wrapper: ProtectedRoute,
      },
      {
        team: TEAM.POD_CX,
        path: '/profile',
        Component: () => <Navigate to={PATHS.ACCOUNT_MANAGEMENT_PROFILE} />,
        Wrapper: React.Fragment,
      },
      {
        team: TEAM.POD_CX,
        path: `${PATHS.ACCOUNT_MANAGEMENT_ROOT}/*`,
        Component: AccountManagement,
        Wrapper: React.Fragment,
      },
      {
        team: TEAM.POD_CX,
        path: `${PATHS.REALTIME_ENERGY}/*`,
        Wrapper: ProtectedRoute,
        predicate: ({ permissions }) =>
          Promise.resolve(permissions?.viewRealtimeDash === true),
        // The common denominator for all users is the profile page.
        // If they can't see the realtime dash, shoot them over to the profile page
        // rather than show a permissions error
        fallback: PATHS.ACCOUNT_MANAGEMENT_PROFILE,
        Component: RealtimeEnergy,
      },
      {
        team: TEAM.POD_CX,
        path: `${PATHS.REALTIME_ENERGY}/detail/compare/:compareSourceKey`,
        Wrapper: ProtectedRoute,
        predicate: ({ permissions }) =>
          Promise.resolve(permissions?.viewRealtimeDash === true),
        // The common denominator for all users is the profile page.
        // If they can't see the realtime dash, shoot them over to the profile page
        // rather than show a permissions error
        fallback: PATHS.ACCOUNT_MANAGEMENT_PROFILE,
        Component: RealtimeEnergy,
      },
      {
        team: TEAM.POD_CX,
        path: '/bank-account/*',
        Wrapper: ProtectedRoute,
        predicate: () => {
          return userCan(['VIEW_EARNINGS'], true)
        },
        // The common denominator for all users is the profile page.
        // If they can't see the realtime dash, shoot them over to the profile page
        // rather than show a permissions error
        fallback: PATHS.ACCOUNT_MANAGEMENT_PROFILE,
        Component: BankAccountSetup,
      },
      {
        team: TEAM.POD_CX,
        path: `${PATHS.BOARDS}/*`,
        Wrapper: FlagEnabledRoute,
        whenFlagIs: true,
        flag: Flags.BOARDS,
        // The common denominator for all users is the profile page.
        // If they can't see the realtime dash, shoot them over to the profile page
        // rather than show a permissions error
        fallback: PATHS.ACCOUNT_MANAGEMENT_PROFILE,
        Component: Boards,
      },
      {
        team: TEAM.POD_CX,
        path: `${PATHS.CASHDASH_HOME}/*`,
        Component: Earnings,
        Wrapper: ProtectedRoute,
        predicate: ({ permissions }) =>
          Promise.resolve(permissions?.viewEarnings === true),
        fallback: PATHS.NOT_FOUND,
      },
      {
        team: TEAM.POD_CX,
        path: `${PATHS.REPORTS}/*`,
        Component: Reports,
        Wrapper: ProtectedRoute,
        predicate: ({ permissions }) =>
          Promise.resolve(permissions?.viewEarnings === true),
        fallback: PATHS.NOT_FOUND,
      },
      {
        team: TEAM.FRONTEND,
        path: PATHS.NOT_FOUND,
        Wrapper: React.Fragment,
        Component: NotFound,
      },
    ]

    this.routes = filterRoutesForDemo(routes)
  },
}
