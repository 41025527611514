import React from 'react'
import colors from '../constants/colors.json'
import createSVGComponent from './createSVGComponent'
const User = createSVGComponent(
  ({ primary, color = colors.grays[20], ...props }) => (
    <svg
      viewBox="0 0 34 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.53625 8.53624C8.53625 13.2014 12.3078 16.9729 16.973 16.9729C21.6381 16.9729 25.4097 13.2014 25.4097 8.53624C25.4097 3.87106 21.6381 0 16.973 0C12.3078 0 8.53625 3.87106 8.53625 8.53624ZM33.948 29.6788C33.948 24.0226 22.6313 21.2421 16.9729 21.2421H16.9751C11.3167 21.2421 0 24.0205 0 29.6788V33.948H33.948V29.6788Z"
        fill={color}
      />
      {primary && (
        <path
          d="M21.5732 25.7305L28.3232 28.7916V33.3481C28.3232 37.5483 25.4402 41.4995 21.5732 42.4609C17.7062 41.4995 14.8232 37.5483 14.8232 33.3481V28.7916L21.5732 25.7305Z"
          fill="#36454F"
          stroke="white"
        />
      )}
    </svg>
  )
)

// eslint-disable-next-line
// @ts-ignore
User.Confirmed = (props) => <User color={colors.greens[20]} {...props} />

// eslint-disable-next-line
// @ts-ignore
User.Rejected = (props) => <User color={colors.reds[40]} {...props} />

export default User
