import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs'
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum'
import Cookies from 'cookies-js'
import jwtDecode from 'jwt-decode'
import * as React from 'react'
import { Profile } from '@voltus/types'
import {
  ENVIRONMENTS,
  isDemoEnabled,
  isProdHost,
  isTestEnv,
} from '@voltus/utils'
import { DATADOG_TOKENS } from './tokens'

// A custom hook to set the user for datadogRum
// This is so logs and errors are associated with a user
export const useDDUser = (profile?: Profile) => {
  const [initializedDataDog, setInitializedDataDog] = React.useState(false)
  React.useEffect(() => {
    if (profile && !initializedDataDog) {
      setInitializedDataDog(true)
      datadogRum.setUser({
        id: profile.id.toString(),
        email: profile.email,
      })
      if (isProdHost()) {
        datadogRum.startSessionReplayRecording()
      }
    }
  }, [profile, initializedDataDog, setInitializedDataDog])
}

let ENV = 'local'
if (process.env.NODE_ENV === ENVIRONMENTS.PROD) {
  ENV = isProdHost() ? ENVIRONMENTS.PROD : ENVIRONMENTS.DEV
}

// We don't want to report this error to datadog. It's benign and can be safely ignored.
export const ignoredErrorMessages = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
]
const ignoreMessage = (msg: string) => {
  if (
    msg.includes('Loading chunk') &&
    msg.includes('siteintercept.qualtrics')
  ) {
    return true
  }

  if (ignoredErrorMessages.includes(msg)) {
    return true
  }

  return false
}

// Wraps the call to datadogRum.init to apply defaults
export const initDDRum = ({
  applicationId,
  clientToken,
  service,
  ...rest
}: RumInitConfiguration) => {
  datadogRum.init({
    applicationId,
    clientToken,
    service,
    site: DATADOG_TOKENS.SITE,
    env: ENV,
    version: process.env.RELEASE_NAME,
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [window.location.origin, /https:\/\/.*\.voltus\.co/],
    beforeSend: (evt, ctx) => {
      if (evt.type === 'error' && evt.error) {
        if (ignoreMessage(evt.error.message)) {
          // Returning `false` prevents the event from being sent to datadog
          return false
        }
        const jwt = Cookies.get('access_token')
        const userEmail = jwt
          ? jwtDecode<{ identity?: { email: string } }>(jwt)?.identity?.email
          : ''
        // Merge additional fields into the existing event context.
        evt.context = {
          ...evt.context,
          isDemoEnabled: isDemoEnabled(),
          userEmail,
          location: window.location.href,
          // @ts-expect-error - we added a custom property to the error object that typescript doesn't know about
          errorHandler: ctx.error?.location,
        }
        return true
      }

      // Allow the caller to configure a beforeSend override
      if (rest.beforeSend) {
        return rest.beforeSend(evt, ctx)
      }

      return true
    },
    enableExperimentalFeatures: ['feature_flags'],
    ...rest,
  })
}

// Wraps the call to datadogLogs.init to apply defaults
export const initDDLogs = ({
  clientToken,
  service,
  ...rest
}: LogsInitConfiguration) => {
  datadogLogs.init({
    clientToken,
    service,
    site: DATADOG_TOKENS.SITE,
    env: ENV,
    // If we want datadog to automatically capture console.errors as logs,
    // we can turn this on. For now, datadog logging is entirely opt-in
    // by using our logger - `@voltus/logger`
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    version: process.env.RELEASE_NAME,
    ...rest,
  })
}

const handleGlobalError = (evt: ErrorEvent) => {
  if (ignoreMessage(evt?.message)) {
    return
  }

  const e = evt.error
  e.source = 'source'
  e.location = 'window-error-handler'
  datadogRum.addError(e)
}

export const initDD = (service: keyof (typeof DATADOG_TOKENS)['SERVICE']) => {
  if (process.env.NODE_ENV === ENVIRONMENTS.PROD && !isTestEnv()) {
    initDDRum({
      applicationId: DATADOG_TOKENS.SERVICE[service].APPLICATION_ID,
      clientToken: DATADOG_TOKENS.SERVICE[service].CLIENT_TOKEN,
      service: service,
    })
    initDDLogs({
      clientToken: DATADOG_TOKENS.SERVICE[service].CLIENT_TOKEN,
      service: service,
    })
    window.addEventListener('error', handleGlobalError)
  }
}

export const ddErrorHandler = (error: Error) => {
  if (ignoreMessage(error.message)) {
    return
  }
  // @ts-expect-error - we added a custom property to the error object that typescript doesn't know about
  // so that we can add it to the datadog context in the `beforeSend` handler above
  error.location = 'react-error-boundary-handler'
  datadogRum.addError(error)
}

const ddLogger = datadogLogs.logger
// Re-export for convenience
export { datadogRum, datadogLogs, ddLogger }
