import { noop } from 'lodash'
import * as React from 'react'
import {
  components,
  DropdownIndicatorProps,
  OptionProps,
  MultiValueProps,
  GroupBase,
  MenuListProps,
  MultiValueGenericProps,
} from 'react-select'
import { AutoSizer, List as VirtualizedList } from 'react-virtualized'

import { StyledIcons } from '../../icons'
import { ActivityIndicator } from '../ActivityIndicator'
import { Box } from '../Box'
import { Checkbox } from '../Checkbox'
import { Flex } from '../Flex'
import { IconButton } from '../IconButton'
import { Input } from '../Input'
import * as SELECTORS from '../SiteSwitcher/siteSwitcher.testSelectors'
import { Text } from '../Text'
import { WithTooltip } from '../Tooltip'
import { Node } from './optionsTree'

const OPTION_HEIGHT = 32
const DROPDOWN_WIDTH = 300
export const VIRTUALIZED_LIST_LENGTH = 500

export function TreeSelectMultiValueLabel<
  ValueType,
  Option extends Node<ValueType>,
>(props: MultiValueGenericProps<Option, true>) {
  const { value } = props.selectProps
  return (
    <components.MultiValueLabel {...props}>
      {Array.isArray(value)
        ? value.filter(({ isLeaf }) => isLeaf).length
        : null}
    </components.MultiValueLabel>
  )
}

/**
 * A custom MultiValueContainer to override react-select's defaults used for SiteSwitcherDropdown
 */
/* eslint-disable complexity */
export function TreeSelectValueContainer<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueProps<Option, IsMulti, Group>) {
  const { children } = props
  const { value, multiValueLabelSuffix, inputValue, placeholder } =
    props.selectProps

  // If defined, children is an array of two: placeholder/items selected in value container, and input
  // Depending on the case, we want to selectively render children
  // BUT We MUST pass the input from react-select so that we dont' lose click handlers
  const [childrenItems, childInput] =
    children && Array.isArray(children) ? children : [null, null]

  // The default react-select behavior is that when there's no value, and the user
  // starts typing to search through the options, the input with the typed text
  // renders instead of the placeholder. The behavior we want is that when the user types
  // the placeholder stays there, since we put the search input box into the menu itself.
  // So to do that we check if there's no value, and also typed search text, then
  // we make sure to continue to render the Placeholder component.
  const isNoneSelectedAndSearching =
    (!value && inputValue) ||
    (value && Array.isArray(value) && value.length === 0 && inputValue)

  const isMultipleValuesSelected =
    value && Array.isArray(value) && value.length > 1

  const isOneValueSelected = value && Array.isArray(value) && value.length === 1

  // Determines whether none of the special cases apply
  // Used to determine whether to pass all children items
  const isNoneApply =
    !isNoneSelectedAndSearching &&
    !isMultipleValuesSelected &&
    !isOneValueSelected

  // Whether or not we should pass all children items
  // We should pass all items if none of the above are true,
  // or if no value is selected and there is user input from search
  const shouldRenderItems = isNoneApply || isNoneSelectedAndSearching

  // This is wacky, but we need to make sure that:
  // a) we are always rendering the same ValueContainer component so it doesn't return a new component on each render
  // b) the children are direct descendants of that ValueContainer component
  // --- (render funcs with <> empty tags don't work and cause selectively weird behavior when switching between conditional blocks)
  return (
    <components.ValueContainer {...props}>
      {/* handle special cases */}
      {isNoneSelectedAndSearching ? (
        <components.Placeholder {...props}>
          {placeholder}
        </components.Placeholder>
      ) : null}
      {isOneValueSelected ? (
        <Text
          fontSize={props.getStyles('multiValueLabel', props).fontSize}
          fontWeight="bold"
          whiteSpace="pre-wrap"
        >
          {value[0].label}
        </Text>
      ) : null}
      {/* render funcs that return multiple elements wrapped in a fragment (</> tags) don't work 
      and cause weird behavior. So the two logical statements below cannot be combined, 
      components must be _direct_ children */}
      {/* childrenItems[0] is an array of the selected values. We only want to show one label so we pick the first*/}
      {isMultipleValuesSelected ? childrenItems?.[0] : null}
      {isMultipleValuesSelected ? (
        <Text
          as="span"
          fontWeight="bold"
          pl={1}
          fontSize={props.getStyles('multiValueLabel', props).fontSize}
        >
          {multiValueLabelSuffix}
        </Text>
      ) : null}
      {/* selectively pass children items */}
      {shouldRenderItems ? childrenItems : null}
      {/* always pass input from react-select so that we don't lose click handlers/behavior */}
      {childInput}
    </components.ValueContainer>
  )
}

/**
 * A custom MultiValueContainer to override react-select's defaults used for MultiSelectDropdown
 */
export function MultiSelectValueContainer<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueProps<Option, IsMulti, Group>) {
  const { children } = props
  const { value, multiValueLabelSuffix, inputValue, placeholder } =
    props.selectProps

  // If defined, children is an array of two: placeholder/items selected in value container, and input
  // Depending on the case, we want to selectively render children
  // BUT We MUST pass the input from react-select so that we dont' lose click handlers
  const [childrenItems, childInput] =
    children && Array.isArray(children) ? children : [null, null]

  const isNoneSelected = value && Array.isArray(value) && value.length === 0

  // The default react-select behavior is that when there's no value, and the user
  // starts typing to search through the options, the input with the typed text
  // renders instead of the placeholder. The behavior we want is that when the user types
  // the placeholder stays there, since we put the search input box into the menu itself.
  // So to do that we check if there's no value, and also typed search text, then
  // we make sure to continue to render the Placeholder component.
  const isNoneSelectedAndSearching =
    (!value && inputValue) || (isNoneSelected && inputValue)

  const isMultipleValuesSelected =
    value && Array.isArray(value) && value.length > 1

  const isOneValueSelected = value && Array.isArray(value) && value.length === 1

  // Determines whether none of the special cases apply
  // Used to determine whether to pass all children items
  const isNoneApply =
    !isNoneSelectedAndSearching &&
    !isMultipleValuesSelected &&
    !isOneValueSelected

  // Whether or not we should pass all children items
  // We should pass all items if none of the above are true,
  // or if no value is selected and there is user input from search
  const shouldRenderItems = isNoneApply || isNoneSelectedAndSearching

  // This is wacky, but we need to make sure that:
  // a) we are always rendering the same ValueContainer component so it doesn't return a new component on each render
  // b) the children are direct descendants of that ValueContainer component
  // --- (render funcs with <> empty tags don't work and cause selectively weird behavior when switching between conditional blocks)
  return (
    <components.ValueContainer {...props}>
      {/* render funcs that return multiple elements wrapped in a fragment (</> tags) don't work 
      and cause weird behavior. Components must be _direct_ children */}

      {/* handle special cases */}
      {isNoneSelectedAndSearching ? (
        <components.Placeholder {...props}>
          {placeholder}
        </components.Placeholder>
      ) : null}

      {isNoneSelected ? null : (
        <Text
          as="span"
          fontWeight="bold"
          fontSize={props.getStyles('multiValueLabel', props).fontSize}
        >
          {multiValueLabelSuffix}
        </Text>
      )}

      {/* selectively pass children items */}
      {shouldRenderItems ? childrenItems : null}

      {/* always pass input from react-select so that we don't lose click handlers/behavior */}
      {childInput}

      {childrenItems?.[0]}
    </components.ValueContainer>
  )
}

/**
 * A custom option rendered. This is a function that returns a React component
 * It has slightly different behavior in tree mode, so we construct different components
 * based on if we're in tree mode or not
 *
 */
export function TreeSelectCustomOption<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */
  ValueType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Node<ValueType>> = GroupBase<Node<ValueType>>,
>(props: OptionProps<Node<ValueType>, IsMulti, Group>) {
  const { children, data, ...rest } = props
  const { value, collapsible, forceRerender } = rest.selectProps
  // Return the Component to be passed to react-select
  // we handle tree mode differently, and render checkboxes inside each option
  const {
    isSelected,
    isSaturated,
    isIndeterminate,
    isCollapsed,
    areChildrenCollapsed,
  } = data.getNodeState(
    // Safe to cast, as we're in tree select mode, and we know we have an array value
    // and Node options
    value as Array<Node<ValueType>> | undefined | null,
    props.options as Array<Node<ValueType>>
  )

  // Allow for an item to have a `isHeader` property.
  // headers will be non-selectable, and render with different text styles
  if (data.isHeader) {
    if (data.childNodes) {
      return (
        <Box>
          <Text.Helper
            color="textColor.muted"
            display="block"
            my={1}
            ml={`${data.depth * 12 + 4}px`}
            textTransform="uppercase"
            title={data.label}
          >
            {data.label}
          </Text.Helper>
        </Box>
      )
    }
  }

  if (isCollapsed) {
    return null
  }

  if (collapsible) {
    return (
      <Flex.Row alignItems="center">
        {data.childNodes?.length ? (
          <Box pl={`${data.depth * 12 + 4}px`}>
            <Box
              aria-label={`${areChildrenCollapsed ? 'Show' : 'Hide'} ${
                data.label
              } child options`}
              as="button"
              border="none"
              bg="transparent"
              height={22}
              cursor="pointer"
              width={16}
              p={0}
              borderRadius={1}
              css={{ '&:hover': { bg: 'grays.10' } }}
              onClick={() => {
                data?.toggleCollapse()
                forceRerender?.(Date.now())
              }}
            >
              {areChildrenCollapsed ? '+' : '-'}
            </Box>
          </Box>
        ) : (
          <Box pl={`${data.depth * 12 + 12}px`} width={16} />
        )}
        <components.Option {...props}>
          <Flex.Row
            flexGrow={1}
            title={data.label}
            data-testid={SELECTORS.OPTION}
            alignItems="center"
          >
            <Flex.Row css={{ pointerEvents: 'none' }}>
              <Checkbox
                onChange={noop}
                isIndeterminate={isIndeterminate}
                value={isSelected || isSaturated}
              />
            </Flex.Row>
            <Box
              as="span"
              maxWidth={250}
              css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {children}
            </Box>
          </Flex.Row>
        </components.Option>
      </Flex.Row>
    )
  }

  // Render the tree select compatible option. a checkbox and label
  return (
    <components.Option {...props}>
      <Flex.Row
        // add ever growing margin to each nested item depending on that items depth
        title={data.label}
        data-testid={SELECTORS.OPTION}
        alignItems="center"
      >
        <Flex.Row css={{ pointerEvents: 'none' }}>
          <Checkbox
            onChange={noop}
            isIndeterminate={isIndeterminate}
            value={isSelected || isSaturated}
          />
        </Flex.Row>
        <Box
          as="span"
          maxWidth={250}
          css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {children}
        </Box>
      </Flex.Row>
    </components.Option>
  )
}

export function FlatCustomOption<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */
  ValueType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Node<ValueType>> = GroupBase<Node<ValueType>>,
>(props: OptionProps<Node<ValueType>, IsMulti, Group>) {
  const { children, data } = props
  // For non tree mode, we still allow for "isHeader" property to identify header objects
  if (data.isHeader) {
    if (data.childNodes?.length) {
      return (
        <Text.Helper
          color="textColor.muted"
          display="block"
          my={1}
          ml={`${data.depth * 12 + 4}px`}
          textTransform="uppercase"
          title={data.label}
        >
          {data.label}
        </Text.Helper>
      )
    }

    return null
  }

  // Render the default react-select option but use a custom Check mark on the left
  return (
    <components.Option {...props}>
      <Box title={data.label}>
        <div className="site-switcher__option" data-testid={SELECTORS.OPTION}>
          {children}
        </div>
      </Box>
    </components.Option>
  )
}

/**
 * Custom Menu List
 * This component is responsible for rendering the options inside a VirtualizedList
 * if the options list is sufficiently large
 */

export function CustomMenuList<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */

  Option extends Node,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MenuListProps<Option, IsMulti, Group>): JSX.Element {
  const { children, maxHeight } = props
  const { isTreeSelect, inputValue, isSearchable, isVirtualized } =
    props.selectProps

  if (!isVirtualized) {
    return (
      <>
        {isTreeSelect && isSearchable ? (
          <Box m={1} borderRadius={1}>
            <Input
              placeholder="Type to Search"
              onChange={noop}
              value={inputValue}
            />
          </Box>
        ) : null}
        <components.MenuList {...props} />
      </>
    )
  }

  const currentRealHeight =
    (children && Array.isArray(children) ? children.length : 0) * OPTION_HEIGHT
  const rowCount = children && Array.isArray(children) ? children.length : 0

  return (
    <>
      {isTreeSelect ? (
        <Box borderRadius={1} m={1}>
          <Input placeholder="Search" onChange={noop} value={inputValue} />
        </Box>
      ) : null}
      <Box
        height={currentRealHeight > maxHeight ? maxHeight : currentRealHeight}
        minWidth={DROPDOWN_WIDTH}
        mb="4px"
      >
        <AutoSizer>
          {({ height, width }) => {
            return (
              <VirtualizedList
                height={height}
                rowCount={rowCount}
                rowHeight={({ index }) =>
                  // We selectively apply a 4px marginTop to root notes
                  // Therefore we need to specify that root nodes are 4px larger
                  // than the default OPTION_HEIGHT
                  children?.[index].props.data.isRoot
                    ? OPTION_HEIGHT + 4
                    : OPTION_HEIGHT
                }
                rowRenderer={({ index, style, key }) => {
                  return (
                    <div
                      key={key}
                      className="site-switcher__virtualized-option-wrapper"
                      style={style}
                    >
                      {children?.[index] ?? null}
                    </div>
                  )
                }}
                width={width}
              />
            )
          }}
        </AutoSizer>
      </Box>
    </>
  )
}

export function CustomDropdownIndicator<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: DropdownIndicatorProps<Option, IsMulti, Group>): JSX.Element {
  const { isFetching } = props.selectProps
  if (isFetching) {
    return (
      <components.DropdownIndicator {...props}>
        <ActivityIndicator.ExtraSmall />
      </components.DropdownIndicator>
    )
  }

  return (
    <components.DropdownIndicator {...props}>
      <StyledIcons.ChevronSelect />
    </components.DropdownIndicator>
  )
}

/**
 * This renders the "X" icon that appears next to a multi label
 */
export function CustomMultiValueRemove(props) {
  // innerProps - excluding css - to be passed to the custom button, as this contains react-select functionality.
  const { css: _, ...innerProps } = props.innerProps
  const { onChange, size } = props.selectProps
  const buttonSize = size === 'small' ? 18 : 24
  const iconSize = size === 'small' ? 12 : 14
  return (
    <WithTooltip openDelay={350} content="Clear">
      <IconButton
        {...innerProps}
        isStrokeBased
        hoverColor="greens.80"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={buttonSize}
        height={buttonSize}
        onClick={() => {
          onChange?.([], { action: 'clear' })
        }}
        aria-label="Clear"
      >
        <StyledIcons.Dismiss width={iconSize} height={iconSize} color="white" />
      </IconButton>
    </WithTooltip>
  )
}
