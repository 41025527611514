import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as testSelectors from '@voltus/constants/testSelectors'
import { ObjectValues } from '@voltus/types'
import { isTestEnv } from '@voltus/utils'
import { StyledProps } from '../../utils/styledSystem'
import { Box } from '../Box'
import stylesheet from './ActivityIndicator.module.scss'

export const SIZES = {
  EXTRA_SMALL: 'extra-small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  FILL: 'fill',
} as const

export const ALIGNS = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
} as const

export const SPEEDS = {
  SLOW: 'slow',
  DEFAULT: 'default',
  FAST: 'fast',
} as const

type ActivityIndicatorProps = StyledProps & {
  size?: ObjectValues<typeof SIZES>
  align?: ObjectValues<typeof ALIGNS>
  speed?: ObjectValues<typeof SPEEDS>
  className?: string
}

function ActivityIndicator({
  size = SIZES.SMALL,
  align,
  speed = SPEEDS.DEFAULT,
  className,
  ...props
}: ActivityIndicatorProps): JSX.Element {
  return (
    <Box
      visibility={isTestEnv() ? 'hidden' : 'visible'}
      className={classnames(
        stylesheet.container,
        stylesheet.spinner,
        {
          [stylesheet.sizeExtraSmall]: size === SIZES.EXTRA_SMALL,
          [stylesheet.sizeSmall]: size === SIZES.SMALL,
          [stylesheet.sizeMedium]: size === SIZES.MEDIUM,
          [stylesheet.sizeLarge]: size === SIZES.LARGE,
          [stylesheet.sizeFill]: size === SIZES.FILL,
          [stylesheet.alignLeft]: align === ALIGNS.LEFT,
          [stylesheet.alignCenter]: align === ALIGNS.CENTER,
          [stylesheet.alignRight]: align === ALIGNS.RIGHT,
          [stylesheet.speedSlow]: speed === SPEEDS.SLOW,
          [stylesheet.speedDefault]: speed === SPEEDS.DEFAULT,
          [stylesheet.speedFast]: speed === SPEEDS.FAST,
        },
        className
      )}
      data-testid={testSelectors.ACTIVITY_INDICATOR}
      {...props}
    />
  )
}

ActivityIndicator.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.oneOf(Object.values(ALIGNS)),
  speed: PropTypes.oneOf(Object.values(SPEEDS)),
}

ActivityIndicator.ExtraSmall = function ExtraSmall({
  size = SIZES.EXTRA_SMALL,
  ...props
}: ActivityIndicatorProps) {
  return <ActivityIndicator size={size} {...props} />
}
ActivityIndicator.Small = function Small({
  size = 'small',
  ...props
}: ActivityIndicatorProps) {
  return <ActivityIndicator size={size} {...props} />
}
ActivityIndicator.Medium = function Medium({
  size = 'medium',
  ...props
}: ActivityIndicatorProps) {
  return <ActivityIndicator size={size} {...props} />
}
ActivityIndicator.Large = function Large({
  size = 'large',
  ...props
}: ActivityIndicatorProps) {
  return <ActivityIndicator size={size} {...props} />
}

ActivityIndicator.SIZES = SIZES
ActivityIndicator.SPEEDS = SPEEDS
ActivityIndicator.ALIGNMENT = ALIGNS

export default ActivityIndicator
