import classnames from 'classnames'
import noop from 'lodash/noop'
import * as React from 'react'
import { Any, AnyObject } from '@voltus/types'
import { StyledIcons } from '../../icons'
import { useUniqueName } from '../../utils'
import { Box } from '../Box'
import { Flex } from '../Flex'
import { IconButton } from '../IconButton'
import { INPUT_SIZES } from './Input.constants'
import stylesheet from './Input.module.scss'

const KEY_E = 69
const KEY_PLUS = 187

export type InputProps = {
  style?: React.CSSProperties
  wrapperAs?: React.ReactNode
  alignText?: 'left' | 'center' | 'right'
  placeholder?: string
  type?: 'number' | 'text' | 'email' | 'phone' | 'password'
  secondaryAction?: React.ReactNode
  className?: string
  label?: string
  name?: string
  value: string
  error?: string
  isClearable?: boolean
  textRight?: boolean
  disabled?: boolean
  prepend?: React.ReactNode
  canShowPassword?: boolean
  onChange: (e: React.ChangeEvent, value: string) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void
  inputRef?: Any
  size?: 'default' | 'small'
  append?: React.ReactNode
  inputProps?: AnyObject
  disallowedChars?: Array<string>
  step?: string
}

const numberInputDisabledKeys = [KEY_E, KEY_PLUS]
/* eslint-disable complexity */
export const Input = React.forwardRef(
  (
    {
      alignText = 'left',
      type = 'text',
      className = '',
      canShowPassword = true,
      disabled = false,
      size = 'default',
      inputProps = {},
      onFocus = noop,
      onBlur = noop,
      onKeyDown = noop,
      value = '',
      name,
      placeholder,
      label,
      error,
      isClearable,
      textRight,
      prepend,
      append,
      inputRef,
      onChange,
      disallowedChars = [],
      secondaryAction,
      wrapperAs = 'div',
      ...props
    }: InputProps,
    ref
  ) => {
    const uniqueName = useUniqueName('Input', name)
    const [isShowingPassword, setIsShowingPassword] = React.useState(false)
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
      onKeyDown(e)
      if (disallowedChars.includes(e.key)) {
        e.preventDefault()
        return
      }

      if (type === 'number') {
        if (numberInputDisabledKeys.includes(e.keyCode)) {
          e.preventDefault()
        }
      }
    }

    const renderLabel = () => {
      return (
        <label htmlFor={uniqueName} className={stylesheet.label}>
          {label}
        </label>
      )
    }

    const renderErrorMessage = () => {
      return <div className={stylesheet.errorMessage}>{error}</div>
    }

    // name prop is wrapped in a getter to allow
    // for not passing a name and the label/input relationship
    // is still maintained automatically
    const clear = (e) => {
      onChange(e, '')
    }

    let localType = type
    if (type === 'password' && canShowPassword && isShowingPassword) {
      localType = 'text'
    }
    return (
      <>
        {label && renderLabel()}
        <Box
          as={wrapperAs}
          className={classnames(stylesheet.wrapper, className, {
            [stylesheet.error]: !!error,
            [stylesheet.wrapperSmall]: size === INPUT_SIZES.SMALL,
          })}
        >
          {prepend ? prepend : null}
          <input
            className={classnames(
              stylesheet.input,
              className,
              stylesheet[`align${alignText}`],
              {
                [stylesheet.disabled]: !!disabled,
                [stylesheet.alignRight]: textRight,
                [stylesheet.clearable]: isClearable && value,
                [stylesheet.small]: size === INPUT_SIZES.SMALL,
              }
            )}
            placeholder={placeholder}
            name={uniqueName}
            id={uniqueName}
            {...props}
            {...inputProps}
            type={localType}
            ref={inputRef || ref}
            value={value || ''}
            onChange={(e) => onChange(e, e.target.value)}
            disabled={!!disabled}
            onKeyDown={handleKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {isClearable && value ? (
            <Flex.FullyCentered height="100%">
              <IconButton
                tooltip="Clear search"
                type="button"
                bg="transparent"
                onClick={clear}
                width={24}
                height={24}
                display="flex"
                alignItems="center"
                justifyContent="center"
                isStrokeBased
              >
                <StyledIcons.Dismiss width={14} height={14} color="slates.30" />
              </IconButton>
            </Flex.FullyCentered>
          ) : null}
          {secondaryAction && !disabled ? (
            <Flex.FullyCentered height="100%">
              {secondaryAction}
            </Flex.FullyCentered>
          ) : null}
          {type === 'password' && canShowPassword ? (
            <Flex.FullyCentered height="100%">
              <IconButton
                type="button"
                bg="white"
                onClick={() => setIsShowingPassword((state) => !state)}
                isStrokeBased
              >
                {isShowingPassword ? (
                  <StyledIcons.EyeClosed color="grays.90" />
                ) : (
                  <StyledIcons.EyeOpen color="grays.90" />
                )}
              </IconButton>
            </Flex.FullyCentered>
          ) : null}
          {append ? append : null}
        </Box>
        {error && renderErrorMessage()}
      </>
    )
  }
)
