export const APPLICATIONS = {
  VOLTAPP: 'voltapp',
  RT_DASH: 'realtime-dashboard',
  VOC: 'voc',
  ADMIN: 'admin',
} as const

/**
 * Event Names. These are passed to eventTracker.track,
 * e.g. `eventTracker.track(EVENTS.LOGIN)`
 */
export const EVENTS = {
  // Generic Events
  LOGIN: 'Login',
  LOGOUT: 'Click Logout',
  APP_LOAD: 'Load Application',
  NAVIGATE: 'Navigate',
  APP_UNLOAD: 'Unload Application',
  SIGNUP_LOGIN: 'Request Voltapp Access via Login Signup Flow',

  // Realtime Dashboard Events
  RT_CSV_DOWNLOAD: 'Download Real Time CSV Data',
  RT_ZOOM: 'Zoom into Real Time Graph',
  RT_ZOOM_CLEAR: 'Clear Real Time Graph Zoom',
  RT_REFRESH: 'Click Refresh Button on RTED',
  RT_CHANGE_RTED_SELECTED_DATA_SOURCES:
    'Update Selected Real Time Data Sources',
  RT_CHANGE_DATE_RANGE: 'Change Real Time Date Range',
  RT_TOGGLE_DRAWER: 'Toggle Real Time Drawer',
  RT_CHANGE_CHART_VIEW_MODE: 'Change Real Time Chart View Mode',
  RT_CHART_MATCH_Y: 'Toggle Match Y Axis on Real Time Chart',
  RT_TOGGLE_AGGREGATE_METHOD: 'Toggle Real Time Aggregate Method',
  RT_REMOVE_CARD_SOURCE: 'Remove Source from Real Time Card',
  RT_FILTER_PEAKS: 'Filter Peaks in Real Time Energy Data',
  RT_OPEN_PEAK_ACCORDION: 'Open Peak Accordion in Real Time Energy Data',
  RT_CHANGE_SETTINGS: 'Change Real Time Chart Settings',

  // Powerbox
  POWERBOX_SELECT_SOURCE: 'Power Box Select Source',
  POWERBOX_DESELECT_SOURCE: 'Power Box Deselect Source',
  POWERBOX_CHANGE_FILTER: 'Power Box Change Filter',
  POWERBOX_CLOSE_WITHOUT_SAVING: 'Power Box Close Without Saving',
  POWERBOX_CLOSE_AND_SAVE: 'Power Box Close and Save',
  POWERBOX_CHANGE_TAB: 'Power Box Change Tab',
  POWERBOX_OPEN_ON_COMBINED_LOAD: 'Open Powerbox on Combined Load',
  POWERBOX_OPEN_ON_SITE: 'Open Powerbox on Sites',
  POWERBOX_OPEN_ON_ISO: 'Open Powerbox on System Loads',

  AGG_POWERBOX_CHANGE_FILTER: 'Change Filter in Combined Load Builder',
  AGG_POWERBOX_CLOSE_WITHOUT_SAVING:
    'Close Combined Load Builder Without Saving',
  AGG_POWERBOX_CLOSE_AND_SAVE: 'Create New Combined Load',
  AGG_VIEW_LOAD: 'View Combined Load',
  CREATE_COMBINED_LOAD: 'Create Combined Load',
  DELETE_COMBINED_LOAD: 'Delete Combined Load',
  RENAME_COMBINED_LOAD: 'Rename Combined Load',

  // Load Comparison Events
  LOAD_COMPARISON_CHANGE_DATE_RANGE: 'Change Load Comparison Date Range',
  LOAD_COMPARISON_OPEN_MODAL: 'Open Load Comparison Modal',
  LOAD_COMPARISON_CLOSE_MODAL: 'Close Load Comparison Modal',
  LOAD_COMPARISON_CHANGE_VIEW_MODE: 'Change Load Comparison Graph View',
  LOAD_COMPARISON_CHANGE_SETTINGS: 'Change Load Comparison Graph Settings',

  // VOC Events
  VOC_VIEW_DISPATCH: 'View VOC Dispatch',
  VOC_CHANGE_PORTFOLIO: 'Change VOC Portfolio',
  VOC_CHANGE_DISPATCH: 'Change VOC Dispatch',
  VOC_CLICK_PERFORMANCE: 'Click VOC Performance Tab',
  VOC_CLICK_PARTICIPATION: 'Click VOC Participation Tab',
  VOC_CREATE_SITE_NOTE: 'Create VOC Site Note',
  VOC_LOAD_MORE: 'Click Load More Dispatches button',

  // Past Performance Events
  PAST_PERFORMANCE_PAGE_LOAD: 'Load Past Performance Page',
  PAST_PERFORMANCE_TOGGLE_SITES: 'Toggle Past Performance to Sites',
  PAST_PERFORMANCE_TOGGLE_DISPATCHES: 'Toggle Past Performance to Dispatches',
  PAST_PERFORMANCE_CHANGE_PORTFOLIO: 'Change Past Performance Portfolio',
  PAST_PERFORMANCE_CHANGE_DATE_RANGE: 'Change Past Performance Date Range',
  PAST_PERFORMANCE_DOWNLOAD_CSV: 'Download Past Performance CSV',
  PAST_PERFORMANCE_CLICK_DISPATCH_ROW: 'Click Past Performance Dispatch Row',
  PAST_PERFORMANCE_CLICK_SITE_ROW: 'Click Past Performance Site Row',
  PAST_PERFORMANCE_DOWNLOAD_SITE_DETAIL:
    'Download Past Performance Site Detail CSV',
  PAST_PERFORMANCE_DOWNLOAD_DISPATCH_DETAIL:
    'Download Past Performance Dispatch Detail CSV',
  PAST_PERFORMANCE_DISPATCH_DETAIL_PAGE_LOAD:
    'Load Past Performance Dispatch Detail Page',
  PAST_PERFORMANCE_SITE_DETAIL_PAGE_LOAD:
    'Load Past Performance Site Detail Page',
  PAST_PERFORMANCE_DISPATCH_DETAIL_CLICK_DISPATCH:
    'Click Dispatch in Past Performance Dispatch Detail',
  PAST_PERFORMANCE_SITE_DETAIL_CLICK_SITE:
    'Click Site in Past Performance Site Detail',

  /**
   * TODO: THESE THREE ARE NOT DONE YET
   * NEED TO DEBOUCE THEM
   */
  PAST_PERFORMANCE_FILTER_DISPATCHES_BY_PROGRAM:
    'Filter Past Performance Dispatches By Program',
  PAST_PERFORMANCE_FILTER_DISPATCHES_BY_EVENT_TYPE:
    'Filter Past Performance Dispatches By Event Type',
  PAST_PERFORMANCE_FILTER_SITES_BY_NAME:
    'Filter Past Performance Sites By Name',
  /**
   * END TODO
   */

  // Navigation Item Events
  CLICK_PROFILE: 'Click Profile Navigation Item',
  CLICK_HOME: 'Click Home Navigation Item',
  CLICK_VOC: 'Click Dispatches Navigation Item',
  CLICK_CASHDASH: 'Click CashDash Navigation Item',
  CLICK_RT_DASH: 'Click Realtime Dashboard Navigation Item',
  CLICK_BOARDS_NAV: 'Click Boards Navigation Item',
  CLICK_ADMIN: 'Click Admin Navigation Item',
  CLICK_PAST_PERFORMANCE_NAV: 'Click Past Performance Navigation Item',
  CLICK_HELP_CENTER: 'Click Help Center',

  // Portfolio Dashboard Events
  PORTFOLIO_CLICK_REGISTRATION_ROW:
    'Click Portfolio Dashboard Registration Row',
  PORTFOLIO_CLICK_SITE_ROW: 'Click Portfolio Dashboard Site Row',
  PORTFOLIO_CHANGE_DATE_RANGE: 'Change Portfolio Dashboard Date Range',
  PORTFOLIO_PICK_PORTFOLIO_FROM_SWITCHER:
    'Change Portfolio Dashboard Portfolio from Switcher',
  PORTFOLIO_PICK_SITE_FROM_SWITCHER:
    'Change Portfolio Dashboard Site from Switcher',
  PORTFOLIO_CHANGE_SITE_FILTER: 'Change Portfolio Dashboard Site Filter',
  PORTFOLIO_CHANGE_PROGRAM_FILTER: 'Change Portfolio Dashboard Program Filter',
  PORTFOLIO_OVERVIEW_CASHDASH_LINK:
    'Click CashDash Link from Portfolio Dash Main Page',
  PORTFOLIO_OVERVIEW_DISPATCHES_PERFORMANCE_LINK:
    'Click Dispatches Performance Link from Portfolio Dash Main Page',
  PORTFOLIO_SITE_DETAILS_GO_TO_RT_DASH:
    'Click Realtime Dashboard Link from Site Detail Page',
  PORTFOLIO_SITE_DETAILS_GO_TO_CASHDASH_PAGE:
    'Click CashDash Link from Site Detail Page',
  PORTFOLIO_OVERVIEW_DOWNLOAD_CSV:
    'Download Registration CSV Data from Portfolio Dashboard',

  // Carbon Reporting Events
  CARBON_SELECT_DATE_RANGE: 'Change Carbon Reporting Date Range',
  CARBON_CLICK_OPEN_MODAL: 'Open Carbon Reporting Education Modal',
  CARBON_CLICK_LEARN_MORE:
    'Click to Learn More in Carbon Reporting Education Modal',
  CARBON_CHANGE_EQUIVALENCY_CAROUSEL: 'Change Carbon Reporting Equivalency',
  CARBON_EXPAND_CARD: 'Expand Carbon Card',
  CARBON_COLLAPSE_CARD: 'Collapse Carbon Card',
  CARBON_DOWNLOAD_REPORT_ON_CARD: 'Open Carbon Download Modal',
  CARBON_DOWNLOAD_REPORT_ON_MODAL: 'Download Carbon Report',

  // Site contact and user profile management
  UPDATE_SITE_CONTACT: 'Update Existing Site Contact',
  ADD_EXISTING_USER_SITE_CONTACT: 'Add Existing User as Site Contact',
  CREATE_USER_AND_ADD_SITE_CONTACT: 'Create New User and Add Site Contact',
  UPDATE_PROFILE: 'Update Profile Information',
  DELETE_SITE_CONTACT: 'Delete Site Contact',

  ADD_CONTACT_MODAL: 'Open Add Contact Modal',
  SUBMIT_CONTACT: 'Submit Add Contact Flow',
  REMOVE_CONTACT: 'Remove Contact',
  EDIT_CONTACT_MODAL: 'Open Edit Contact Modal',
  EDIT_CONTACT: 'Save Contact Edits',
  CHANGE_CONTACT_TYPE: 'Change Contact Type',

  // CashDash Events
  CASHDASH_CHANGE_DATE_RANGE: 'CashDash Change Date Range',
  CASHDASH_CHANGE_SITE_FILTER: 'CashDash Change Site Filter',
  CASHDASH_CHANGE_PROGRAM_FILTER: 'CashDash Change Program Filter',
  CASHDASH_CHANGE_PORTFOLIO: 'CashDash Change Portfolio',
  CASHDASH_HELP_ICON_FORECASTED_VALUE:
    'CashDash Click Help Icon for Total Forecasted Value',
  CASHDASH_HELP_ICON_CASH_RECEIVED:
    'CashDash Click Help Icon for Cash Received to Date',
  CASHDASH_HELP_ICON_UNDISBURSED_CASH:
    'CashDash Click Help Icon for Undisbursed Cash',
  CASHDASH_HELP_ICON_LAST_PAYMENT: 'CashDash Click Help Icon for Last Payment',
  CASHDASH_CHANGE_LINE_ITEMS_STATUS_FILTER:
    'CashDash Change Line Items Status Filter',
  CASHDASH_CHANGE_LINE_ITEMS_CATEGORY_FILTER:
    'CashDash Change Line Items Category Filter',
  CASHDASH_EXPAND_LINE_ITEM: 'CashDash Expand Line Item',
  CASHDASH_CLICK_PAYMENT_ITEM: 'CashDash Click Payment Item',
  CASHDASH_CHANGE_CHART_VIEW: 'CashDash Change Chart View',
  CASHDASH_DOWNLOAD_LINE_ITEMS_CSV: 'CashDash Download Line Items CSV',
  CASHDASH_DOWNLOAD_PAYMENT_CSV: 'CashDash Download Payment CSV',
  USER_INITIATED_HELP_HERO_TOUR: 'User Initiated HelpHero Tour',
  CASHDASH_COMPLETE_DOWNLOAD_OF_LINE_ITEM_CSV:
    'Cashdash Complete Download Of Line Item CSV',
  CASHDASH_DOWNLOAD_MONTHLY_OR_LINE_ITEMS_CSV:
    'Cashdash Download Monthly OR Line Items CSV',
  CLICK_PIE_CHART_ICON_IN_PAYMENT_PAGE: 'Click Pie Chart Icon in Payment Page',
  CLICK_PERFORMANCE_LINK_FROM_PAYMENT_PAGE:
    'Click Performance Link from Payment Page',
  CASHDASH_FILTER_MONTHLY_OR_REPORT_CALENDAR:
    'Cashdash Filter Monthly OR Report Calendar',
  CASHDASH_CHANGE_TAB: 'CashDash Change Tab',
  CASHDASH_PAYMENTS_TABLE_PAGE_LOAD: 'CashDash Payments Table Page Load',
  CASHDASH_DOWNLOAD_PAYMENT_FROM_PAYMENT_TAB_CSV:
    'CashDash Download Payment From Payment Tab CSV',

  PAYMENT_DETAIL_PAGE_LOAD: 'Payment Details Page Load',
  PAYMENT_DETAIL_GROUP_BY: 'Payment Details Group Line Items By',

  // Admin Events
  ADMIN_CLICK_ACCOUNT_MGMT:
    'Click Account Management Navigation Item from Admin',
  ADMIN_CLICK_VIEW_APPLICATIONS:
    'Click View Applications Navigation Item from Admin',
  ADMIN_CLICK_VIEW_EDIT_USERS:
    'Click View/Edit Users Navigation Item from Admin',
  ADMIN_CLICK_VIEW_EDIT_FACILITIES:
    'Click View/Edit Facilities Navigation Item from Admin',

  // Customer Account Management Events
  CAM_CLICK_VERTICAL_NAV: 'CAM Click Vertical Nav',
  CAM_PROFILE_PAGE_LOAD: 'CAM Profile Page Load',
  CAM_USERS_PAGE_LOAD: 'CAM Users Page Load',
  CAM_USERS_CLICK_ADD_USER:
    'CAM Click Add Users Button to Open Modal from Users Page',
  CAM_USERS_CLICK_CREATE_USER:
    'CAM Click Create User Button from Add User Modal',
  CAM_USERS_CLICK_EDIT_USER:
    'CAM Click Edit User Icon Button to Open Modal from Users Page',
  CAM_USERS_CLICK_UPDATE_USER:
    'CAM Click Update User Button from Edit User Modal',
  CAM_USERS_CLICK_DEACTIVATE_USER:
    'CAM Click Deactivate User Button from Edit User Modal',
  CAM_USERS_CLICK_SITES: 'CAM Click Sites Number to Open Modal from Users Page',
  CAM_USERS_CLICK_RESEND_INVITE:
    'CAM Click Resend Invite Icon Button from Users Page',
  CAM_USERS_UPDATE_DISPATCH_CONTACT:
    'CAM Click Save Button from Site Contact Modal',
  CAM_FILES_OVERVIEW_PAGE_LOAD: 'CAM Files Overview Page Load',
  CAM_FILES_OVERVIEW_CLICK_COLLECT:
    'CAM Files Overview Click Collect Files Button',
  CAM_FILES_OVERVIEW_CLICK_UPLOAD:
    'CAM Files Overview Click Upload Files Button',
  CAM_FILES_OVERVIEW_CLICK_REDASH:
    'CAM Files Overview Click See Files in Redash Button',
  CAM_FILES_OVERVIEW_CLICK_COPY_LINK:
    'CAM Files Overview Click Copy Link Button',
  CAM_FILES_OVERVIEW_CLICK_ROW: 'CAM Files Overview Click Table Row',
  CAM_FILES_UPLOAD_PAGE_LOAD: 'CAM Files Upload Page Load',
  CAM_FILES_UPLOAD_CLICK_SUBMIT: 'CAM Files Upload Click Submit Button',
  CAM_FILES_DOWNLOAD_PAGE_LOAD: 'CAM Files Download Page Load',
  CAM_FILES_DOWNLOAD_CLICK_DOWNLOAD: 'CAM Files Download Click Download Button',
  CAM_BILLS_OVERVIEW_PAGE_LOAD: 'CAM Bills Overview Page Load',
  CAM_BILLS_OVERVIEW_CLICK_ROW: 'CAM Bills Overview Click Table Row',
  CAM_FILES_OVERVIEW_CLICK_ROW_COPY_LINK:
    'CAM Files Overview Click Copy Row Link Button',
  CAM_BILLS_OVERVIEW_CLICK_BULK_DOWNLOAD:
    'CAM Bills Overview Click Bulk Download Button',
  CAM_BILLS_OVERVIEW_CONFIRM_KCE_INGEST:
    'CAM Bills Overview Confirm KCE Ingest',
  CAM_BILLS_OVERVIEW_CONFIRM_ARCHIVE: 'CAM Bills Overview Confirm Archive',
  CAM_BILLS_DETAIL_PAGE_LOAD: 'CAM Bills Detail Page Load',
  CAM_BILLS_DETAIL_CLICK_EDIT: 'CAM Bills Detail Click Edit Button',
  CAM_BILLS_DETAIL_CLICK_CANCEL: 'CAM Bills Detail Click Cancel Button',
  CAM_BILLS_DETAIL_CLICK_SAVE: 'CAM Bills Detail Click Save Button',
  CAM_BILLS_DETAIL_CLICK_DOWNLOAD: 'CAM Bills Detail Click Download Button',
  CAM_BILLS_DETAIL_CLICK_SELECT_UA:
    'CAM Bills Detail Click Select Utility Accounts',
  CAM_BILLS_DETAIL_CLICK_CONFIRM_UA:
    'CAM Bills Detail Confirm Utility Accounts',
  CAM_ACTION_ITEMS_PAGE_LOAD: 'CAM Action Items Page Load',
  CAM_ACTION_ITEMS_CTA_CLICK: 'CAM Action Items CTA Click',
  CAM_ACTION_ITEMS_UPLOAD_SUBMIT_CLICK: 'CAM Action Items Upload Submit Click',
  CAM_ACTION_ITEMS_CHANGE_TABS: 'CAM Action Items Change Tabs',

  // Demo Mode Events
  DEMO_MODE_ENABLED: 'Demo Mode Enabled',
  DEMO_MODE_DISABLED: 'Demo Mode Disabled',

  // Customer Comms Events
  CUSTOMER_COMMS_SEND_EMAILS_CLICK: 'Customer Comms Send Emails Click',
  CUSTOMER_COMMS_SEND_TEST_EMAILS_CLICK:
    'Customer Comms Send Test Emails Click',
  CUSTOMER_COMMS_RETRY_PAGE_LOAD: 'Customer Comms Retry Page Load',

  //Dispatch Queue Events
  DISPATCH_QUEUE_PAGE_LOAD: 'Dispatch Queue Page Load',
  DISPATCH_QUEUE_SEND_EMAIL_CLICK: 'Dispatch Queue Send Email Click',

  // Action Items Admin Events
  ACTION_ITEMS_ADMIN_PAGE_LOAD: 'Action Items Admin Page Load',
  NEW_ACTION_ITEMS_CREATED: 'New Action Items Created',
  ACTION_ITEMS_UPDATED: 'Action Items Updated',

  // Market Events Events
  MARKET_EVENTS_PAGE_LOAD: 'Market Events Page Load',
  MARKET_EVENTS_SEND_COMMS_CLICK: 'Market Events Send Comms Click',
  MARKET_EVENTS_SEND_COMMS_CUSTOM_EMAIL_CLICK:
    'Market Events Send Comms Custom Email Click',
  MARKET_EVENTS_SEND_COMMS_EVENT_END_NOTIFICATION_CLICK:
    'Market Events Send Comms Event End Notification Click',
  MARKET_EVENTS_SEND_COMMS_DISPATCH_NOTIFICATION_CLICK:
    'Market Events Send Comms Dispatch Notification Click',
  MARKET_EVENTS_SEND_COMMS_REMINDER_NOTIFICATION_CLICK:
    'Market Events Send Comms Reminder Notification Click',

  // Reporting events
  REPORTS_SITE_REPORT_LOAD: 'Load Site Report',
  REPORTS_SWITCH_PORTFOLIO: 'Switch Portfolio in Reports',
  REPORTS_SWITCH_SITE: 'Switch Site in Reports',
  REPORTS_SELECT_SECTION: 'Select Section Tab in Reports',
  REPORTS_GO_TO_RTED: 'Go to Real Time Energy Data from Reports',
  REPORTS_GO_TO_PAST_PERFORMANCE: 'Go to Past Performance from Reports',
  REPORTS_SITE_REPORT_LEAVE: 'Leave Site Report',
} as const

/**
 * Properties are passed as the second argument to `eventTracker.track`
 * This enum allows us to standarize on property names / syntax
 */
export const PROPERTIES = {
  /* Mixpanel reserved properties */
  EMAIL: '$email',
  NAME: '$name',
  CREATED: '$created',
  /*******/

  /* User data for events, rather than profiles */
  USER_EMAIL: 'User Email',
  USER_PHONE: 'User Phone',
  USER_LANDLINE: 'User Landline',
  USER_FIRST_NAME: 'User First name',
  USER_LAST_NAME: 'User Last Name',
  USER_ID: 'User Id',
  USER_TITLE: 'User Title',
  DISPATCH_ROLE: 'Dispatch Role',

  // Our custom properties
  ACCOUNT_MANAGER: 'Account Manager',
  ACTIVE_EVENT: 'Active Event',
  APP: 'App',
  CASE_TYPES: 'Case Types',
  CASHDASH_CATEGORY_FILTER: 'Cashdash Category Filter',
  CASHDASH_TAB_VALUE: 'CashDash Tab Value',
  COUNT: 'Count',
  DATA_SOURCE_KEY: 'Data Source Key',
  DATA_SOURCE_NAME: 'Data Source Name',
  DATA_SOURCE_TYPE: 'Data Source Type',
  DATE_RANGE_APPLIED: 'Date Range Applied',
  DATE_RANGE_SELECTED: 'Date Range Selected',
  DEMO_MODE: 'Demo Mode',
  DISPATCH_EVENT_TYPE: 'Dispatch Event Type',
  DISPATCH_ID: 'Dispatch ID',
  DOWNLOAD_METER_DATA: 'If Meters Included',
  DOWNLOAD_UNITS: 'Units',
  END_DATE: 'End Date',
  END_TIME: 'End Time',
  FACILITY_ID: 'Facility ID',
  FACILITY_NAME: 'Facility Name',
  HELP_HERO_TOUR_NAME: 'Help Hero Tour Name',
  HOST: 'Host',
  INTERVAL: 'Interval',
  IS_CUSTOM_DATE_RANGE: 'Is Custom Date Range',
  LAST_EVENT: 'Last Event',
  LAST_LOAD: 'Last Load',
  LINE_ITEM_CATEGORY: 'Line Item Category',
  LINE_ITEM_STATUS: 'Line Item Status',
  LINE_ITEM_SUBCATEGORY: 'Line Item Subcategory',
  LOAD_TIME: 'Load Time',
  NAVIGATE_FROM: 'Navigate From',
  NAVIGATE_FROM_HASH: 'Navigate From Hash',
  NAVIGATE_FROM_PATHNAME: 'Navigate From Pathname',
  NAVIGATE_FROM_SEARCH: 'Navigate From Search',
  NAVIGATE_TO: 'Navigate To',
  NAVIGATE_TO_HASH: 'Navigate To Hash',
  NAVIGATE_TO_PATHNAME: 'Navigate To Pathname',
  NAVIGATE_TO_SEARCH: 'Navigate To Search',
  ORGANIZATION_ID: 'Organization ID',
  ORGANIZATION_NAME: 'Organization Name',
  OR_TIME_PERIOD: 'OR Time Period',
  PAYMENT_ID: 'Payment ID',
  PAYMENT_DATE: 'Payment Date',
  PORTFOLIO_ID: 'Portfolio ID',
  PORTFOLIO_NAME: 'Portfolio Name',
  PORTFOLIO_SELECTED: 'Portfolio Selected',
  PROGRAM_ID: 'Program ID',
  PROGRAM_NAME: 'Program Name',
  PROGRAM_FILTER_APPLIED: 'Program Filter Applied',
  PROGRAM_FILTER_SELECTED: 'Program Filter Selected',
  REGISTRATION_SFID: 'Registration SFID',
  ROLE: 'Role',
  ROLE_ID: 'Role ID',
  RT_DASH_DATE_RANGE_FORMATTED: 'Realtime Dashboard Date Range (formatted)',
  RT_DASH_DATE_RANGE_END: 'Realtime Dashboard Date Range End',
  RT_DASH_DATE_RANGE_START: 'Realtime Dashboard Date Range Start',
  RT_DASH_SELECTED_UNITS: 'Realtime Dashboard Selected Units',
  SITE_FILTER_APPLIED: 'Site Filter Applied',
  SITE_ID: 'Site ID',
  SITE_FILTER_SELECTED: 'Site Filter Selected',
  START_DATE: 'Start Date',
  START_TIME: 'Start Time',
  TIME_STAMP: 'Timestamp',
  TIME_TO_DOWNLOAD_MS: 'Time to Download in ms',
  TITLE: 'Title',
  VISITED: 'Visited',
  NUM_PROGRAMS: 'Number of Programs',
  CARBON_DATE_RANGE_END: 'Carbon Reporting Date Range End',
  CARBON_DATE_RANGE_START: 'Carbon Reporting Date Range Start',
  CARBON_AGGREGATE_BY: 'Carbon Reporting Aggregated By',
  CARBON_SELECTED_DROPDOWN_VALUE: 'Carbon Reporting Selected Dropdown Value',
  CARBON_EQUIVALENCY_TYPE: 'Carbon Equivalency Type',
  CARBON_PDF_EQUIVALENCIES: 'Carbon PDF Equivalency Types',
  CAM_USERS_DISPATCH_CONTACT_NEW_SITES: 'New Site Assignments',
  CAM_USERS_DISPATCH_CONTACT_UPDATE_ROLES: 'Sites with Updated Dispatch Roles',
  CAM_USERS_DISPATCH_CONTACT_REMOVED_SITES: 'Removed Site Assignments',
  CAM_USERS_EDIT_TARGET_ID: 'User Targeted for Edits',
  CAM_USERS_UPDATED_FIELDS: 'Updated User Details',
  CAM_USER_DEACTIVATE_TARGET_ID: 'User Targeted for Deactivation',
  CAM_USER_RESEND_TARGET_ID: 'User Targeted for Resend Invite',
  CUSTOMER_COMMS_FAILED_CONTACTS: 'Customer Comms Failed Contacts',
  CUSTOMER_COMMS_ALL_CONTACTS: 'Customer Comms All Contacts',
  DISPATCH_TYPE: 'Dispatch Type',
  ACTION_ITEM_TYPE: 'Action Item Type',
  ACTION_ITEM_COUNT: 'Action Item Count',
  ACTION_ITEM_ID: 'Action Item ID',
  FILE_UPLOAD_TYPE: 'File Upload Type',
  ACTIVE_TAB: 'Active Tab',
} as const
